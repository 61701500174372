import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { useAuthState } from 'react-firebase-hooks/auth';
import { app, auth, db } from './../../firebase';
import { useUser } from './../../contexts/UserContext.js';
import LoginPage from "./../../pages/LoginPage/LoginPage.jsx"

const PrivateRoute = ({ children }) => {
  const [user, loading] = useAuthState(auth);
  const { userData } = useUser();
  const [isUserDataLoaded, setIsUserDataLoaded] = useState(false);

  if (loading || (user && !userData)) {
    return <div>Loading...</div>;
  }

  return user ? children : <LoginPage />;
};

export default PrivateRoute;