import React, { createContext, useState, useEffect, useContext } from 'react';
import { collection, query, where, getDocs, getCountFromServer, doc, updateDoc, orderBy, arrayUnion, arrayRemove } from 'firebase/firestore';
import { httpsCallable } from '@firebase/functions';
import { db, functions } from './../firebase.js'; // Ensure these are correctly set up
import { useSecondHeader } from './SecondHeaderContext.js';

// Utility Functions
const getStartOfYear = (date) => new Date(Date.UTC(date.getUTCFullYear(), 0, 1));
const formatDate = (date) => {
    const d = new Date(date);
    const month = `${d.getUTCMonth() + 1}`.padStart(2, '0');
    const day = `${d.getUTCDate()}`.padStart(2, '0');
    return `${d.getUTCFullYear()}-${month}-${day}`;
};

// Create the context
const UnitDataCacheContext = createContext();

// Create a provider component
export const UnitDataCacheProvider = ({ children }) => {
    const [records, setRecords] = useState([]);
    const [activationsUnit, setActivationsUnit] = useState("år");
    const [activationsRelativePercentage, setActivationsRelativePercentage] = useState(1);
    const [activationsCounts, setActivationsCounts] = useState([]);
    const [activityHeatmapData, setActivityHeatmapData] = useState([]);
    const [activityOverTimeData, setActivityOverTimeData] = useState([]);
    const [activityOverTimeContext, setActivityOverTimeContext] = useState(undefined);

    const { currentUnit } = useSecondHeader();

     // Compute the total activations
    const activationsTotal = () => {
        return activationsCounts.reduce((total, category) => total + category.count, 0);
    };

    const getOtherUserInfo = async (ids) => {
        console.log("Firebase - ")
        try {
            const otherUsersInfoEndpoint = httpsCallable(functions, 'getUsersInfo');
            const result = await otherUsersInfoEndpoint({ user_ids: ids });
            return result.data;
        } catch (error) {
            console.error("Error fetching other user info: ", error);
            return [];
        }
    };

    const setCustomEval = async (record, category, unitId, userId) => {
        console.log("Firebase - ")
        try {
            console.log("Setting custom eval: ", record, category, unitId, userId);
            const recordRef = doc(db, 'units', unitId, "inference-records", record.record_id);
            await updateDoc(recordRef, `user_evals.${userId}`, category);
            record.user_evals = { ...record.user_evals, [userId]: category };
            return true;
        } catch (error) {
            console.error("Error setting custom eval: ", error);
            return false;
        }
    };

    const addCustomCategory = async (record, category, unitId) => {
        console.log("cust- ", record, category, unitId)
        try {
            const recordRef = doc(db, 'units', unitId);
            await updateDoc(recordRef, `custom_categories`, arrayUnion(category));
            return true;
        } catch (error) {
            console.error("cust- Error setting custom category: ", error);
            return false;
        }
    };

    const removeCustomEvalCategory = async (record, category, unitId) => {
        console.log("cust- remove", record, category, unitId)
        try {
            const recordRef = doc(db, 'units', unitId);
            await updateDoc(recordRef, `custom_categories`, arrayRemove(category));
            return true;
        } catch (error) {
            console.error("cust- Error setting custom category: ", error);
            return false;
        }
    }

    const removeCustomEval = async (record, unitId, userId) => {
        console.log("Firebase - ")
        try {
            console.log("Removing custom eval: ", record, unitId, userId);
            const recordRef = doc(db, 'units', unitId, "inference-records", record.record_id);
            await updateDoc(recordRef, `user_evals.${userId}`, null);
            return true;
        } catch (error) {
            console.error("Error removing custom eval: ", error);
            return false;
        }
    }

    const getRecords = async (unitId, from, to) => {
        console.log("records- Firebase - getRecords")
        console.log("unitId", unitId)
        console.log("from", from)
        console.log("to", to)
        try {
            const q = query(
                collection(db, `/units/${unitId}/inference-records`),
                where("timestamp", ">", from),
                where("timestamp", "<", to),
                orderBy("timestamp", "desc")
            );
            const snapshot = await getDocs(q);
            const recordsData = snapshot.docs.map(doc => ({
                ...doc.data(),
                timestamp: new Date(doc.data().timestamp), // Convert to Date object
                record_id: doc.id,
            }));
            console.log("records- result", recordsData)
            setRecords(recordsData);
        } catch (error) {
            console.error("records- Error fetching records: ", error);
        }
    };

    const getActivityOverTimeData = async (unitId, from, to, timeUnit) => {
        console.log("Firebase - getActivityOverTimeData - ")
        console.log("activityOverTime-unitId", unitId)
        console.log("activityOverTime-from", from)
        console.log("activityOverTime-to", to)
        console.log("activityOverTime-timeUnit", timeUnit)

        const mapTimeUnit = (timeUnit) => {
            switch (timeUnit) {
                case "week":
                    return "uge";
                case "month":
                    return "måned";
                case "year":
                    return "år";
                default:
                    return "uge";
            }
        };
        const mappedTimeUnit = mapTimeUnit(timeUnit);
        try {
            const aotEndpoint = httpsCallable(functions, 'activityOverTime');
            console.log("activityOverTime- mappedTimeUnit:-" + mappedTimeUnit)
            const result = await aotEndpoint({ unit_id: unitId, from: from, to: to, time_unit: mappedTimeUnit });
            
            const data = result.data;
            /*
            For translations:
            const data = result.data.map((category) => ({
                ...category,
                category: category.category === "UNDEFINED" ? category.category : `${unitId}.${category.category}`,
            }));*/
            console.log("activityOverTime- back end result ", data);

            // Sets the UNDEFINED category last in the array then reverses
            for (let i = 0; i < data.length; i++) {
                if (data[i].category === "UNDEFINED") {
                    data.push(data.splice(i, 1)[0]);
                    break;
                }
            }

            setActivityOverTimeData(data);
        } catch (error) {
            console.error("Error fetching activity over time data: ", error);
        }
    };

    const getActivityHeatmapData = async (unitId, from, to) => {
        console.log("Firebase - getActivityHeatmapData")
        console.log("UnitDataCache - Fetching activity heatmap data...");
        console.log("UnitDataCache - Unit ID: ", unitId);
        console.log("Current Unit: ", currentUnit);
        console.log("From: ", from);
        console.log("To: ", to);
        const timeUnit = "uge";
        try {
            const tempActivity = [];
            for (const category of currentUnit.eval_categories) {
                const q = query(
                    collection(db, `/units/${unitId}/inference-records`),
                    where("timestamp", ">", from),
                    where("timestamp", "<", to),
                    where("eval", "==", category)
                );
                const snapshot = await getDocs(q);
                const countsByDay = Array(7).fill(0);
                snapshot.docs.forEach((record) => {
                    const day = new Date(record.data().timestamp).getDay(); // Get day of the week
                    countsByDay[day]++;
                });
                countsByDay.unshift(...countsByDay.splice(1)); // Adjust to start from Monday
                tempActivity.push({ category, counts: countsByDay });
            }
            tempActivity.sort((a, b) => a.category === "UNDEFINED" ? 1 : -1);
            console.log("heatMap- Setting activity heatmap data: ", tempActivity);
            setActivityHeatmapData(tempActivity);
        } catch (error) {
            console.error("Error fetching activity heatmap data: ", error);
        }
    };

    // TODO: Provide timeUnit instead of prevFrom and prevTo
    const getActivationsData = async (unitId, from, to, prevFrom, prevTo) => {
        console.log("Firebase - getActivationsData")
        try {
            const tempActivationsCounts = [];
            for (const category of currentUnit.eval_categories) {
                console.log("prev- ", from)
                console.log("prev- to", to)
                const q = query(
                    collection(db, `/units/${unitId}/inference-records`),
                    where("timestamp", ">", from),
                    where("timestamp", "<", to),
                    where("eval", "==", category)
                );
                const snapshot = await getCountFromServer(q);
                tempActivationsCounts.push({ category, count: snapshot.data().count });
            }

            // Sets the UNDEFINED category last in the array
            for (let i = 0; i < tempActivationsCounts.length; i++) {
                if (tempActivationsCounts[i].category === "UNDEFINED") {
                    tempActivationsCounts.push(tempActivationsCounts.splice(i, 1)[0]);
                    break;
                }
            }

            setActivationsCounts(tempActivationsCounts);

            const prevQ = query(
                collection(db, `/units/${unitId}/inference-records`),
                where("timestamp", ">", prevFrom),
                where("timestamp", "<", prevTo)
            );

            console.log("prev- prevFrom", prevFrom)
            console.log("prev- prevTo", prevTo)
            const prevSnapshot = await getCountFromServer(prevQ);
            const totalCount = tempActivationsCounts.reduce((a, b) => a + b.count, 0)
            const prevCount = prevSnapshot.data().count;
            console.log("prev- prevResult", prevSnapshot.data())
            console.log("prev- totalCount", totalCount)
            console.log("prev- prevCount", prevCount)
            setActivationsRelativePercentage(
                ((totalCount / prevCount - 1) * 100).toFixed(1)
            );
        } catch (error) {
            console.error("Error fetching activations data: ", error);
            console.error("Unit ID: ", unitId);
            //console.log("Auth", db.auth());
        }
    };

    return (
        <UnitDataCacheContext.Provider value={{
            records,
            activationsUnit,
            setActivationsUnit,
            activationsRelativePercentage,
            activationsCounts,
            activationsTotal,
            activityHeatmapData,
            activityOverTimeData,
            activityOverTimeContext,
            getOtherUserInfo,
            setCustomEval,
            removeCustomEval,
            getRecords,
            getActivityOverTimeData,
            getActivityHeatmapData,
            getActivationsData,
            addCustomCategory,
            removeCustomEvalCategory
        }}>
            {children}
        </UnitDataCacheContext.Provider>
    );
};

// Custom hook to use the UnitDataCacheContext
export const useUnitDataCache = () => {
    return useContext(UnitDataCacheContext);
};
