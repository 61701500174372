import React, { useState, useEffect, useContext, useCallback } from 'react';
import { useUnitDataCache } from '../../../../contexts/UnitDataCacheContext';
import { useSecondHeader } from './../../../../contexts/SecondHeaderContext';
//import { Dialog } from '@material-ui/core'; // Assuming you're using Material-UI
import DatePickerComponent from '../../../../components/DatePicker/DatePicker';
import './ImagesSubPage.css'; // Import the styles
import { useParams } from 'react-router-dom';
import RecordDialog from './RecordDialog/RecordDialog.jsx';
import { db } from './../../../../firebase.js';
import { Modal } from '@mui/material';
import { DialogRecordProvider } from './RecordDialog/RecordDialogContext';
import { useTranslation } from 'react-i18next';

import down_arrow from "./../../../../assets/icons/down-arrow.svg"

const ImagesSubPage = () => {
    const { t, i18n } = useTranslation();
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const currentRecord = {}
    const [ currentRecordX, setCurrentRecordX ] = useState(undefined)
    const [currentIndex, setCurrentIndex] = useState(0);

    const { locationId, unitId } = useParams();
    const { records, getRecords } = useUnitDataCache();
    const [ localRecords, setLocalRecords ] = useState([]);
    const { currentUnit, firstUse, getFirstUse, getUnitData } = useSecondHeader();
    const [currentPage, setCurrentPage] = useState(0);
    const [pageSize, setPageSize] = useState(50);
    const [activeFilter, setActiveFilter] = useState('');
    const [flipSort, setFlipSort] = useState(false);
    const [showPagePaginatorDropdown, setShowPagePaginatorDropdown] = useState(false);
    
    // Defaulto to year to date
    const defaultStartUnix = (new Date(new Date().getFullYear(), 0, 1)).getTime();
    const defaultEndUnix = (new Date()).getTime();

    const [startUnix, setStartUnix] = useState(defaultStartUnix);
    const [end_unix, setEndUnix] = useState(defaultEndUnix);

    const [colors] = useState([
        '#74D5F2',
        '#BD92F4',
        '#81F3D5',
        '#FF8989',
        '#81ECF3',
        '#53C0FF',
        '#ED9BD2',
        '#FF89B0',
        '#90AAFF',
        '#81F3AD'
    ]);

    const totalPages = () => {
        console.log("Total pages called")
        console.log("localRecords.length", localRecords.length)
        console.log("pageSize", pageSize)
        return Math.ceil(localRecords.length / pageSize)
    }

    // On mount
    useEffect(() => {
        console.log("Images page - Fetching data... (on mount)");

        if (!currentUnit) {
            console.log("Fetching unit data...", unitId);
            console.log("Unit id:")
            console.log(unitId)
            getUnitData(unitId)
        }

        getRecords(unitId, defaultStartUnix, defaultEndUnix);
    }, []);

    useEffect(() => {
        if(unitId) {
            getFirstUse(unitId);
        }
    }, [unitId]);

    useEffect(() => {
        filterRecords()
    }, [records, activeFilter, flipSort]);

    /*
    useEffect(() => {
        console.log("currentImgIndex useEffect", currentImgIndex)
        const record = records[currentImgIndex];
        setCurrentRecordX(record);
    }, [currentImgIndex])*/

    const filterRecords = () => {
        console.log("filterRecords called")
        var data = records.filter((elem) => activeFilter === '' ? true : elem.eval === activeFilter);
        if (flipSort) data = data.reverse();
        setLocalRecords(data);
    }

    const handleIndexChange = (newIndex) => {
        //setCurrentIndex(newIndex);
        console.log('Current image index changed:', newIndex);
        setCurrentRecordX(localRecords[newIndex]);
        // Add any additional logic you need here when the index changes

        // Refresh records in order to see the newest user evals.
        getRecords(unitId, startUnix, end_unix);
    };

    const handleSortChange = () => {
        setFlipSort(flipSort == false);
        setCurrentPage(0);
        filterRecords();
    };

    const handleDatePicked = async (start_unix, end_unix) => {
        setStartUnix(start_unix);
        setEndUnix(end_unix);
        await getRecords(unitId, start_unix, end_unix);
    };

    const handlePaginatorClick = (size) => {
        setPageSize(size);
        setCurrentPage(0);
    };

    const handlePaginatorDropdownClick = () => {
        setShowPagePaginatorDropdown(!showPagePaginatorDropdown);
    };

    const prevPage = () => {
        if (currentPage > 0) setCurrentPage(currentPage - 1);
    };

    const nextPage = () => {
        console.log("currentPage", currentPage)
        console.log("totalPages", totalPages())
        if (currentPage < totalPages() - 1) setCurrentPage(currentPage + 1);
    };

    const openImageDialog = (record, index) => {
        setCurrentRecordX(record);
        //setCurrentIndex(index);
        setCurrentIndex(index);
        setIsDialogOpen(true);

        // Refresh records in order to see the newest user evals.
        getRecords(unitId, startUnix, end_unix);
    };

    const closeDialog = () => {
        setCurrentRecordX(undefined);
        setIsDialogOpen(false);
    };

    const getCellClassName = (index, side) => {
        var className = "cdk-cell";

        const lastInPage = Math.min(pageSize, localRecords.length - currentPage * pageSize);
        if (index == 0) {
            className += " first-row-" + side;
        } else if (index == lastInPage - 1) {
            className += " last-row-" + side;
        } else if (side == "right") {
            className += " right-cell"
        }
        
        return className;
    }

    return (
      <div className="ImagesSubPage">
        <div className="outer-wrapper">
            <div className="main-wrapper">
                <div className="records-tabs">
                    <button className="aris-button-primary" onClick={() => setActiveFilter('')}>
                        Alle
                    </button>
                    {currentUnit?.eval_categories?.map((category, i) => (
                        <button
                            key={category}
                            className="aris-button-secondary"
                            onClick={() => setActiveFilter(category)}
                            style={{
                                borderColor: activeFilter === category ? (category === 'UNDEFINED' ? '#A5A5A5' : colors[i]) : ''
                            }}
                        >
                            {t(`${currentUnit.model}.${category}`)}
                        </button>
                    ))}
                    <button className="sort-button" onClick={handleSortChange}>
                        <span>Sorter {flipSort ? "sidst" : "f\u00f8rst"}</span>
                        <div className="date-picker-toggle-arrow sort-arrow">
                            <img
                                src={down_arrow}
                                alt="Sort Arrow"
                                style={{ transform: flipSort ? 'rotate(180deg)' : '' }}
                            />
                        </div>
                    </button>
                    <DatePickerComponent
                        onDateSelected={handleDatePicked}
                    />
                </div>
                <div className="records-table">
                    <table>
                        <thead>
                            <tr className="card-header">
                                <th>Årstal</th>
                                <th>Måned</th>
                                <th>Dag</th>
                                <th>Tidspunkt</th>
                                <th>Kategori</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {localRecords
                                .slice(currentPage * pageSize, (currentPage + 1) * pageSize)
                                .map((element, index) => (
                                    <tr key={element.timestamp}>
                                        <td className={getCellClassName(index, "left")}>
                                            {new Date(element.timestamp).getFullYear()}
                                        </td>
                                        <td className="cdk-cell" style={{ textTransform: 'capitalize' }}>
                                            {new Intl.DateTimeFormat('da-DK', { month: 'short' }).format(new Date(element.timestamp))}
                                        </td>
                                        <td className="cdk-cell" style={{ textTransform: 'capitalize' }}>
                                            {new Intl.DateTimeFormat('da-DK', { day: '2-digit', weekday: 'long' }).format(new Date(element.timestamp))}
                                        </td>
                                        <td className="cdk-cell" >{new Date(element.timestamp).toLocaleTimeString()}</td>
                                        <td className="cdk-cell"
                                            style={{
                                                color: element.eval !== 'UNDEFINED' ? colors[currentUnit.eval_categories.indexOf(element.eval)] : '#A5A5A5'
                                            }}
                                        >
                                            {t(`${element.eval}`)}
                                        </td>
                                        <td className={getCellClassName(index, "right")}>
                                            <button
                                                className="aris-button-primary"
                                                style={{ fontSize: '14px', lineHeight: '14px', padding: '9px 12px' }}
                                                onClick={() => openImageDialog(element, pageSize * currentPage + index)}
                                            >
                                                Se billede
                                            </button>
                                        </td>
                                    </tr>
                                ))}
                        </tbody>
                    </table>
                    <div className="table-footer">
                        {localRecords.length > 0 && (
                            <div className="page-paginator-dropdown-wrapper" onClick={handlePaginatorDropdownClick}>
                                <div className="page-paginator-text">Pr. side: {pageSize}</div>
                                <div className="page-paginator-dropdown-toggle">
                                    <img
                                        className={`tiny-icon ${showPagePaginatorDropdown ? 'page-paginator-dropdown-toggle-on' : ''}`}
                                        src={down_arrow}
                                        alt="."
                                    />
                                </div>
                                {showPagePaginatorDropdown && (
                                    <div className="page-paginator-dropdown">
                                        {[10, 20, 50, 100].map((size) => (
                                            <div key={size} className="page-paginator-dropdown-elem" onClick={() => handlePaginatorClick(size)}>
                                                {size}
                                            </div>
                                        ))}
                                    </div>
                                )}
                            </div>
                        )}
                        <div className="page-paginator-navigator">
                            <div
                                className={`page-paginator-navigator-elem ${currentPage === 0 ? 'page-paginator-navigator-elem-disabled' : ''}`}
                                onClick={prevPage}
                            >
                                <img className="page-paginator-left-arrow" src={down_arrow} alt="." />
                            </div>
                            <div className="page-paginator-navigator-current">
                                {currentPage * pageSize} -{' '}
                                {currentPage === totalPages() - 1 ? localRecords.length : (currentPage + 1) * pageSize} / {localRecords.length}
                            </div>
                            <div
                                className={`page-paginator-navigator-elem ${currentPage === totalPages() - 1 ? 'page-paginator-navigator-elem-disabled' : ''}`}
                                onClick={nextPage}
                            >
                                <img className="page-paginator-right-arrow" src={down_arrow} alt="." />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <DialogRecordProvider 
            onIndexChange={handleIndexChange}
        >
            <Modal
                open={isDialogOpen}
                onClose={closeDialog}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                { isDialogOpen?
                    <div>
                        <h1>
                            <RecordDialog
                                isOpen={isDialogOpen}
                                currentRecordX={currentRecordX}
                                index={currentIndex}
                            />
                        </h1>
                    </div> : <></>
                }
            </Modal>
        </DialogRecordProvider>
      </div>
    );
};

export default ImagesSubPage;
