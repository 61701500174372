import React, { useState, useCallback, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useSecondHeader } from '../../../../contexts/SecondHeaderContext';
import './ThirdHeader.css';

const ThirdHeader = () => {
    
    const [state, setState] = useState('left');
    const { getLocationData, getUnitsData, currentLocation, resetCurrentUnit } = useSecondHeader();
  
    const navigate = useNavigate();
    const location = useLocation();

    // Set the state from the url
    useEffect(() => {
        if (location.pathname.includes("images")) {
            setState('right');
        } else {
            setState('left');
        }
    }, [location.pathname]);

    function pathToSubPage(subPage) {
        const pathArray = window.location.pathname.split('/'); // Split the path into segments
        pathArray[pathArray.length - 1] = subPage; // Replace the last segment
        const newPath = pathArray.join('/'); // Join the segments back together
        return newPath; // Return the new path
    }
  
    const handleToggleLeft = useCallback(() => {
        setState('left');
        navigate(pathToSubPage("dashboard"));
    }, []);
  
    const handleToggleRight = useCallback(() => {
        setState('right');
        navigate(pathToSubPage("images"));
    }, []);
    
    return (
        <div className="ThirdHeader">
            <div className="sub-sub-header">
                <div className="toggle">
                    <div className={`${state === 'left' ? 'toggle-active ' : 'toggle-inactive'}`}>
                        <div className="inner-toggle" onClick={handleToggleLeft}>
                            <div className={`toggle-text ${state === 'left' ? 'toggle-white' : ''}`}>
                                Dashboard
                            </div>
                        </div>
                    </div>
                    <div className={`${state === 'left' ? 'toggle-inactive' : 'toggle-active'}`}>
                        <div className="inner-toggle" onClick={handleToggleRight}>
                            <div className={`toggle-text ${state === 'right' ? 'toggle-white' : ''}`}>
                                Billeder
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ThirdHeader;