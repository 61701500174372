import React, { useCallback, useEffect, useState, useRef } from "react";
import { getDownloadURL, ref } from 'firebase/storage';
import { storage } from './../../../../../firebase.js';
import { Box, Typography, Button, Tooltip } from '@mui/material';
import { useUnitDataCache } from './../../../../../contexts/UnitDataCacheContext.js';
import { useSecondHeader } from './../../../../../contexts/SecondHeaderContext.js';
import { auth } from './../../../../../firebase.js';
import { useAuthState } from 'react-firebase-hooks/auth';
import { useUser } from './../../../../../contexts/UserContext.js';
import { useDialogRecord } from './RecordDialogContext';

import left_arrow from "./../../../../../assets/icons/left_arrow.svg"
import right_arrow from "./../../../../../assets/icons/right_arrow.svg"
import data_error_svg from "./../../../../../assets/icons/data_error.svg"
import loading_spinner from "./../../../../../assets/icons/loading_spinner.svg"
import add_button_icon from "./../../../../../assets/icons/add-button.svg"

import { useTranslation } from "react-i18next";

import "./RecordDialog.css";
import { use } from "i18next";

const RecordDialog = ({isOpen, currentRecordX, index}) => {
    const { t } = useTranslation();

    const [user, loading, error] = useAuthState(auth);
    const { userData }  = useUser();
    const { currentImgIndex, currentRecord, cycleImage } = useDialogRecord();
    const [loadingPage, setLoadingPage] = useState(true);
    const [loadFailed, setLoadFailed] = useState(false);
    const [imageUrl, setImageUrl] = useState(null);
    const { records, setCustomEval, userEvals, setUserEvals, getOtherUserInfo, removeCustomEval, addCustomCategory, removeCustomEvalCategory } = useUnitDataCache();
    const { currentUnit } = useSecondHeader();
    const [user_info, setUser_info] = useState({});
    const [localUserEvals, setLocalUserEvals] = useState({});
    const [isEdittingCategories, setIsEdittingCategories] = useState(false);
    const [localCustomEvalCategories, setLocalCustomEvalCategories] = useState([]);
    const [isEnteringNewCategory, setIsEnteringNewCategory] = useState(false);
    const [inputValue, setInputValue] = useState("");
    const inputRef = useRef(null);
    const spanRef = useRef(null);
    const [currentInputWidth, setCurrentInputWidth] = useState(0);

    const colors = [
        '#74D5F2',
        '#BD92F4',
        '#81F3D5',
        '#FF8989',
        '#81ECF3',
        '#53C0FF',
        '#ED9BD2',
        '#FF89B0',
        '#90AAFF',
        '#81F3AD'
    ];

    useEffect(() => {
        console.log("RecordDialog - useEffect - index: ", index);
        cycleImage(index, '');
    }, [index]);

    useEffect(() => {
        console.log("RecordDialog - useEffect - currentImgIndex: ", currentImgIndex);
    }, [currentImgIndex]);

    useEffect(() => {
        console.log("RecordDialog - useEffect - isOpen: ", isOpen);
        if (isOpen) {
            console.log("RecordDialog - useEffect - currentRecord: ", currentRecordX);
            if (currentRecordX && currentRecordX.img_name) {
                loadImage();
            }
        }
    }, [isOpen, currentRecordX]);

    useEffect(() => {
        console.log("RecordDialog - useEffect - currentRecordX: ", currentRecordX);
        if (currentRecordX) {
            if (currentRecordX.user_evals) {
                getUserInfo();
            } else {
                setLocalUserEvals({});
            }
        }
    }, [currentRecordX]);

    useEffect(() => {
        if (currentUnit) {
            console.log("cust- currentUnit", currentUnit)
            console.log("cust- eval", currentUnit.custom_categories)
            setLocalCustomEvalCategories(currentUnit.custom_categories ?? []);
        }
    }, [currentUnit]);

    const adjustInputWidth = () => {
        if (spanRef.current) {
            const spanWidth = spanRef.current.offsetWidth;
            setCurrentInputWidth(spanWidth + 2)
        }
    }
    useEffect(() => {
        // Adjust the width of the input field based on the width of the text in the span
        adjustInputWidth();
      }, [inputValue]);

    const loadImage = async () => {
        
        if (!currentRecordX) {
            return
        }
        
        setLoadingPage(true);
        setLoadFailed(false);
        const imgRef = ref(storage, `images/${currentRecordX.img_name}`);
        try {
            const url = await getDownloadURL(imgRef);
            setImageUrl(url);
            setLoadingPage(false);
        } catch (error) {
            setLoadFailed(true);
            setLoadingPage(false);
        }
    };

    const handleCycle = (index) => {
        console.log("handleCycle", index)
        cycleImage(index, '');
    };

    const voteCategoryPressed = (category) => {
        // If the user has already voted for this category, remove their vote
        if (localUserEvals && (localUserEvals[user.uid] === category)) {
        //if (localUserEvals[user.uid] === category) {
          removeVote();
        } else {
          voteCategory(category);
        } 
    }

    const removeVote = async () => {
        console.log("vote- remove")
        try {
            // Call to database to remove the user evaluation
            await removeCustomEval(currentRecordX, currentUnit.unit_id, user.uid);
            // Update the local state with the new user evaluations
            //setUserEvals({ ...userEvals, [user.uid]: '
            console.log("rem-", localUserEvals)
            const localUserEvalsNewValue = { ...localUserEvals };
            console.log("rem- 1", localUserEvalsNewValue)
            console.log("rem- 1b:", user.uid)
            delete localUserEvalsNewValue[user.uid];

            console.log("rem- 2", localUserEvalsNewValue)

            setLocalUserEvals(
                localUserEvalsNewValue
            );

        } catch (error) {
            console.error('Error removing custom evaluation:', error);
        }
    }

    const voteCategory = async (evaluation) => {
        try {
          // Call to database to update the user evaluation
          console.log("User - ", user) 
          console.log("Record:", currentRecordX)
          console.log("currentUnit:", currentUnit)
            const userUid = user.uid;
            setLocalUserEvals({
                ...localUserEvals, 
                [user.uid]: evaluation
            });

          await setCustomEval(currentRecordX, evaluation, currentUnit.unit_id, user.uid);
          // Update the local state with the new user evaluations
          //setUserEvals({ ...userEvals, [user.uid]: evaluation });
          getUserInfo();
        } catch (error) {
          console.error('Error setting custom evaluation:', error);
        }
    };

    const getUserInfo = async () => {
        console.log("records- getUserInfo - currentRecordX: ", currentRecordX);
        if (currentRecordX?.user_evals) {
            try {
                //let result = await this.data_cache.testUserInfo(ids)

                console.log("records- calling getOtherUserInfo")
                let result = await getOtherUserInfo(Object.keys(currentRecordX.user_evals));
                console.log("records- result: ", result);
                if (result) {
                    // Convert to dictionary with user ID as key
                    var dictionary = {};
                    result.forEach(user => {
                        dictionary[user.id] = user;
                    });
                    console.log("records- getUserInfo - dictionary: ", dictionary);
                    setUser_info(dictionary);
                    console.log("records- setting local user evals: ", currentRecordX.user_evals);
                    setLocalUserEvals(currentRecordX.user_evals);
                } else {
                    setUser_info({});
                }
            } catch (error) {
                // If user does not exist
                console.log('Error fetching user info:', error);
            }
        }
    }

    const getUserVoteIDs = useCallback((category) => {
        console.log("records- getUserVoteIDs")
        if (!localUserEvals) {
            return [];
        }
    
        const ids = Object.keys(localUserEvals).filter(key => localUserEvals[key] === category);
        console.log("records- uservotes 1- ids- category:", category, ids)
        // If the current user has voted, move their ID to the front of the list
        const currentUser = userData;
        if (currentUser) {
            const index = ids.indexOf(currentUser.uid);
            if (index > -1) {
                ids.splice(index, 1); // Remove the user's ID from its original position
                ids.unshift(currentUser.uid); // Add the user's ID to the front
            }
        }
        console.log("records- uservotes ids:", ids)
        console.log("records- userinfo:", user_info)
        return ids;
    }, [localUserEvals, userData, user_info]);

    const getUserVoteNames = (category) => {
        if (!localUserEvals) {
            return '';
        }
    
        const ids = Object.keys(localUserEvals).filter(key => localUserEvals[key] === category);
    
        // If the current user has voted, remove their name from the list
        const currentUser = userData;
        if (currentUser) {
            const index = ids.indexOf(currentUser.uid);
            if (index > -1) {
                ids.splice(index, 1); // Remove the user's ID from the list
            }
        }
    
        if (ids.length === 0) {
            return '';
        }
    
        const names = ids.map(id => {
            const user = user_info[user.id];
            return user?.name || '??';
        });
    
        return names.join(', ');
    };

    const vizTime = () => {
        if (!currentRecordX || !currentRecordX.timestamp) {
            return '';
        }
    
        const date = new Date(currentRecordX.timestamp);
    
        const options = {
            weekday: 'long',
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit'
        };
    
        // Format the date
        const formattedDate = new Intl.DateTimeFormat('da-DK', options).format(date);
    
        // Capitalize the first letter
        return formattedDate.charAt(0).toUpperCase() + formattedDate.slice(1).replace(',', ' kl.');
    };

    const getColorForUser = (userId) => {
        console.log("records- getColorForUser - userId: ", userId);
        console.log("records- getColorForUser - user_info: ", user_info);
        console.log("records- getColor data:", user_info[userId])
        console.log("records- getColor color:", user_info[userId]["color"])
        const color = user_info[userId]["color"]
        return color ? color: "#eb6e65"
    }

    const toggleEditCategories = () => {
        setIsEdittingCategories(!isEdittingCategories);
    }

    const newCategoryPressed = () => {
        if (isEnteringNewCategory) {
            newCategorySubmitted();
            setInputValue(null)
            adjustInputWidth();
            setIsEnteringNewCategory(false);
        } else {
            console.log("input- inputValue:", inputValue)
            console.log("input- inputRef:", inputRef)
            console.log("input- spanRef:", spanRef)
            setInputValue(null)
            adjustInputWidth();
            setIsEnteringNewCategory(true);
            adjustInputWidth();

            console.log("input- 2 inputValue:", inputValue)
            console.log("input- 2 inputRef:", inputRef)
            console.log("input- 2 spanRef:", spanRef)
        }
    }

    const inputKeyDown = (event) => {
        if (event.key === 'Enter') {
            newCategorySubmitted();
            setInputValue(null)
            adjustInputWidth();
            setIsEnteringNewCategory(false);
        }
    }

    const inputOnBlur = () => {
        newCategorySubmitted();
        setInputValue(null)
        adjustInputWidth();
        setIsEnteringNewCategory(false);
    }

    const newCategorySubmitted = () => {
        const newCategory = inputValue;
        if (newCategory && newCategory.length != 0 && !localCustomEvalCategories.includes(newCategory)) {
            setLocalUserEvals({
                ...localUserEvals,
                [user.uid]: newCategory
            });
            setCustomEval(currentRecordX, newCategory, currentUnit.unit_id, user.uid);
            var localCustomEvalCategoriesNewValue = localCustomEvalCategories
            localCustomEvalCategoriesNewValue.push(newCategory)
            setLocalCustomEvalCategories(localCustomEvalCategoriesNewValue);

            // Update remote
            addCustomCategory(currentRecordX, newCategory, currentUnit.unit_id)
        }
    }

    const deleteCategoryClicked = (index) => {
        const category = localCustomEvalCategories[index]
        const localCustomEvalCategoriesNewValue = localCustomEvalCategories
        localCustomEvalCategoriesNewValue.splice(index, 1)
        setLocalCustomEvalCategories(localCustomEvalCategoriesNewValue);
        removeCustomEvalCategory(currentRecordX, category, currentUnit.unit_id)
    }

    const handleChange = (event) => {
        setInputValue(event.target.value);
    };

    return (
        <div className="RecordDialog">
            {currentRecordX && currentUnit &&
            <div className="dialog">
                <div className="img-wrapper">
                    <div className="record-info-box">
                    <div className="record-info-title">Billede</div>
                    <div className="record-info-value">{currentRecordX.img_name}</div>
                    </div>
                    <div className="cycle-btns">
                    <div
                        className="cycle-btn"
                        onClick={() => currentImgIndex >= 1 && handleCycle(currentImgIndex - 1)}
                        style={currentImgIndex < 1 ? { opacity: 0.4, cursor: 'default' } : {}}
                    >
                        <img src={left_arrow} alt="Forrige billede" />
                    </div>
                    <div
                        className="cycle-btn"
                        onClick={() => currentImgIndex < records.length - 1 && handleCycle(currentImgIndex + 1)}
                        style={currentImgIndex >= records.length - 1 ? { opacity: 0.4, cursor: 'default' } : {}}
                    >
                        <img src={right_arrow} alt="Næste billede" />
                    </div>
                    </div>
                    <div className="img-inner-wrapper" style={{ justifyContent: loadFailed || loading ? 'center' : 'flex-start' }}>
                    {!loading && !loadFailed && <img className="inference-img" src={imageUrl} alt="Inferens billede" />}
                    {loadFailed && (
                        <>
                        <img src={data_error_svg} width="80" height="80" alt="Error loading" />
                        <div className="no-img">Billede kunne ikke indlæses</div>
                        </>
                    )}
                    {loading && (
                        <div className="loading-img-wrapper">
                        <img className="loading-img" src={loading_spinner} width="80" height="80" alt="Loading" />
                        </div>
                    )}
                    </div>
                </div>
                <span className="vertical-seperator"></span>
                <div className="info-wrapper">
                    <div className="record-info-box">
                    <div className="record-info-title">Evaluering</div>
                    <div
                        className="record-info-value"
                        style={{
                        color: currentRecordX.eval !== 'UNDEFINED' ? colors[currentUnit.eval_categories.indexOf(currentRecordX.eval)] : '#A5A5A5',
                        }}
                    >
                        {t(currentRecordX.eval)}
                    </div>
                    </div>
                    <div className="record-info-box">
                    <div className="record-info-title">Brugerevaluering</div>
                    <div className="category-section">
                        <div className="category-buttons">
                            {currentUnit.eval_categories.map((category, i) => (
                                <div
                                    key={i}
                                    className="category-button"
                                    onClick={() => voteCategoryPressed(category)}
                                    style={{
                                        color: category !== 'UNDEFINED' ? colors[i] : '#A5A5A5',
                                    }}
                                >
                                    {t(category)}
                                </div>
                            ))}
                            {localCustomEvalCategories.map((category, i) => (
                                <div
                                    key={i}
                                    className="user-added-category-button"
                                    onClick={() => voteCategoryPressed(category)}
                                    style={{
                                        color: category !== 'UNDEFINED' ? colors[i % colors.length] : '#A5A5A5',
                                    }}
                                >
                                    {category}
                                    { isEdittingCategories ?
                                     <div className="categoryDeleteButton" onClick={()=>{deleteCategoryClicked(i)}}>{"🚫"}</div>
                                     : <></>
                                    }
                                </div>
                            ))}
                            <div className="category-input-wrapper">
                            <span
                                ref={spanRef}
                                style={{
                                position: "absolute",
                                visibility: "hidden",
                                whiteSpace: "pre",
                                }}
                            >
                                {inputValue || "Ny..."}
                            </span>
                            { isEnteringNewCategory ?
                            <div 
                                className="category-button"
                            >
                                <input 
                                    id="new-category-input" 
                                    type="text" 
                                    placeholder="Ny..." 
                                    onKeyDown={inputKeyDown} 
                                    ref={inputRef}
                                    value={inputValue}
                                    onChange={handleChange}
                                    onBlur={inputOnBlur}
                                    style={{
                                        margin: "0px",
                                        minWidth: "0",
                                        width: `${currentInputWidth}px`,
                                        padding: "0px",
                                        boxSizing: "border-box",
                                        border: "1px solid transparent", // Consistent border, no changes on focus
                                        outline: "none",           // Prevent the focus outline from appearing
                                      }}
                                    autoFocus
                                />
                            </div>
                            :
                            <></>
                            }
                            </div>
                        </div>
                        <div className="category-votes-wrapper">
                        {currentUnit.eval_categories.map((category, i) => (
                            <div key={i} className="user-category-votes">
                            {getUserVoteIDs(category).map((_user, j) => ( user_info[_user] &&
                                <div
                                key={j}
                                className={`user-vote icon-text ${_user === user.uid ? 'current-user-vote' : ''}`}
                                style={{ backgroundColor: getColorForUser(_user) }}
                                title={user_info[_user]["name"]}
                                >
                                {user_info[_user]["name"].slice(0, 2).toUpperCase()}
                                </div>
                            ))}
                            </div>
                        ))}
                        {localCustomEvalCategories.map((category, i) => (
                            <div key={i} className="user-category-votes">
                            {getUserVoteIDs(category).map((_user, j) => ( user_info[_user] &&
                                <div
                                key={j}
                                className={`user-vote icon-text ${_user === user.uid ? 'current-user-vote' : ''}`}
                                style={{ backgroundColor: getColorForUser(_user) }}
                                title={user_info[_user]["name"]}
                                >
                                {user_info[_user]["name"].slice(0, 2).toUpperCase()}
                                </div>
                            ))}
                            </div>
                        ))}
                        {   isEnteringNewCategory ?
                            <div className="user-category-votes"></div>
                            :<></>
                        }
                        </div>
                        <div
                                className="add-category-button"
                                onClick={newCategoryPressed}
                            >
                                <img src={add_button_icon} />
                        </div>
                    </div>
                    
                    </div>
                    <div className="record-info-box">
                    <div className="record-info-title">Tidspunkt</div>
                    <div className="record-info-value">{vizTime(currentRecordX.timestamp)}</div>
                    </div>
                    <div className="record-info-box">
                    <div className="record-info-title">AI model</div>
                    <div className="record-info-value">{currentUnit.model}</div>
                    </div>
                    <div className="record-info-box">
                    <div className="record-info-title">Inferens</div>
                    <div className="record-info-value">
                        {currentRecordX.inference.map((inf, i) => (
                        <div key={i} style={{ display: 'flex', justifyContent: 'space-between', minWidth: '8ch' }}>
                            <span>{`${i + 1}.`}</span>
                            <span>{inf.toPrecision(5)}</span>
                        </div>
                        ))}
                    </div>
                    </div>
                </div>
                </div>
}
        </div>
    );
}

export default RecordDialog;