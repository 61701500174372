import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { getUnitStateListener, getUnitActivationsTodayCount, getLatestUse } from './../../../hooks/unitDataHooks';
import './UnitCard.css';
import { useSecondHeader } from './../../../contexts/SecondHeaderContext';

function UnitCard({ unit }) {
    const { locationId } = useParams();
    const [liveColor, setLiveColor] = useState('var(--gray-500)');
    const [unitState, setUnitState] = useState({});
    const navigate = useNavigate();
    const { setCurrentUnit } = useSecondHeader();

    useEffect(() => {
        if (!unit) return;

        const fetchUnitData = async () => {
            const path = `/units/${unit.unit_id}/inference-records`;
            unit.activations = await getUnitActivationsTodayCount(path);
            unit.latest_use = await getLatestUse(path);
            setUnitState(unit);
        };

        fetchUnitData();

        const unsubscribeFirestore = getUnitStateListener(unit.unit_id, {
            next: (log) => {
                if (new Date(log.timestamp) < new Date(Date.now() - 24 * 60 * 60 * 1000)) {
                    setLiveColor('var(--gray-500)');
                } else {
                    switch (log.state) {
                        case 'Ready':
                            setLiveColor('var(--success-500)');
                            break;
                        case 'Error':
                            setLiveColor('var(--error-500)');
                            break;
                        default:
                            setLiveColor('var(--warning-500)');
                            break;
                    }
                }
            },
            error: (error) => {
                console.error("Error in unit state listener:", error);
            }
        });

        return () => {
            if (unsubscribeFirestore) unsubscribeFirestore();
        };
    }, [unit]);

    const navigateToUnit = () => {
        setCurrentUnit(unit);
        navigate(`/locations/${locationId}/unit/${unit.unit_id}/dashboard`);
    };

    return (
        <div className="UnitCard">
            <div className="card-wrapper" style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                <div className="login-container card-container" onClick={navigateToUnit} style={{ width: '100%', maxWidth: '620px', cursor: 'pointer' }}>
                    <div className="card-header">
                        <div className="card-header-title">
                            {unit.display_name}
                        </div>
                    </div>
                    <div className="card-body" style={{ marginBottom: 0, borderBottomLeftRadius: 0, borderBottomRightRadius: 0 }}>
                        <figure className="img-wrapper">
                            <img className="poster-image" src={unit.poster_url} width="588" height="250" alt="Poster image" />
                        </figure>
                    </div>
                    <div className="card-body location-bar" style={{ borderTopLeftRadius: 0, borderTopRightRadius: 0 }}>
                        <div className="location-bar-left">
                            <span className="units-count-text">
                                <div className="units-count-label">Aktiveringer i dag:</div>
                                <div className="units-count-number">{unit.activations}</div>
                            </span>
                            <span className="units-count-text">
                                <div className="units-count-label">Seneste anvendelse:</div>
                                <div className="units-count-number">{unit.latest_use || ''}</div>
                            </span>
                        </div>
                        <div className="location-bar-right">
                            <div className="live-activity-wrapper">
                                <div className="live-activity-dot" style={{ backgroundColor: liveColor }}></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default UnitCard;
