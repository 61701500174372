import React, { useState, useEffect, useCallback } from "react";
import "./DatePicker.css";
import down_arrow from "./../../assets/icons/down-arrow.svg"
import left_arrow from "./../../assets/icons/left_arrow.svg"
import right_arrow from "./../../assets/icons/right_arrow.svg"
import { useSecondHeader } from "../../contexts/SecondHeaderContext";
import { useTranslation } from 'react-i18next';

const DatePicker = ({children, onDateSelected, horizontalAnchor, type, defaultTime = new Date()}) => {
    const { t, i18n } = useTranslation();
    const { firstUse, getFirstUse, currentUnit } = useSecondHeader();
    const TimeUnit = {
        YEAR: "year",
        MONTH: "month",
        WEEK: "week"
    }

    const [selectedTimeLabel, setSelectedTimeLabel] = useState("");

    // The highlightedTime defaults to the current year.
    // TODO: Default to currently selected time, if any.
    const defaultTimeUnit = type == "week" ? TimeUnit.WEEK : TimeUnit.YEAR;
    const [highlightedTime, setHighlightedTime] = useState(defaultTime);
    const [highlightedTimeUnit, setHighlightedTimeUnit] = useState(defaultTimeUnit);
    const [shownYear, setShownYear] = useState(defaultTime.getFullYear());
    
    // The selected time defaults to the current year.
    const [selectedTime, setSelectedTime] = useState(defaultTime);
    const [selectedTimeUnit, setSelectedTimeUnit] = useState(defaultTimeUnit);

    const [expanded, setExpanded] = useState(false);

    const months = ["jan", "feb", "mar", "apr", "maj", "jun", "jul", "aug", "sep", "okt", "nov", "dec"];

    const weekColumns = 9;

    const closePicker = () => {
        setExpanded(false);
    }

    useEffect(() => {
        document.addEventListener("click", (event) => {
            if (event.target.closest(".DatePicker") === null) {
                closePicker();
            }
        });

        setSelectedTimeLabel(calculateSelectedTimeLabel(selectedTime, selectedTimeUnit));
    }, [])

    useEffect(() => {
        if(!expanded) {
            /* 
             * Reset the picker such that it is ready to show
             * the the selected time, once it's opened again.
             */
            showSelectedTime();
        }
    }, [expanded, selectedTime]);

    useEffect(() => {
        if (currentUnit) {
            getFirstUse(currentUnit.unit_id);
        }
    }, [currentUnit]);

    const getWrapperClass = () => {
        if (horizontalAnchor === "right") {
            return "right-anchored";
        }

        return "default-anchored";
    }

    const showSelectedTime = () => {
        setShownYear(selectedTime.getFullYear());
        setHighlightedTime(selectedTime);
        setHighlightedTimeUnit(selectedTimeUnit);
    }

    const calculateSelectedTimeLabel = (time, unit) => {
        const yearToDateLabel = "ÅTD";

        var label = "";

        var _time = time ? time : defaultTime;
        var _unit = unit ? unit : defaultTimeUnit;

        const year = _time.getFullYear();

        switch (_unit) {
            case TimeUnit.YEAR:
                label = year == (new Date()).getFullYear() ? yearToDateLabel : year;
                break;
            case TimeUnit.MONTH:
                label = year + " " + months[selectedTime.getMonth()];
                break;
            case TimeUnit.WEEK:
                label = year + " uge " + getWeekNumber(_time);
                break;
            default:
                break;
        }

        return label;
    }

    const toggleClicked = useCallback(() => {
        console.log("toggleClicked");
        if (expanded) {
            closePicker();
        } else {
            setExpanded(true);
        }
    }, [expanded]);

    // year: Date
    const weeksInYear = useCallback(() => {
        return 52 // TODO: get correct number of weeks in year.
    }, [shownYear]);

    const weekMatrix = () => {
        var output = [[]];
        const total_weeks = weeksInYear();
        const items = Math.ceil(weeksInYear() / weekColumns) * weekColumns;
        for (var i = 0; i < items; i++) {
            const week = (i < total_weeks) ? i + 1 : "";
            if(output[output.length - 1].length == weekColumns) {
                output.push([week]);
            } else {
                output[output.length - 1].push(week);
            }
        }
        
        return output;
    }

    const getYearClass = () => {
        if (highlightedTimeUnit === TimeUnit.YEAR
            && shownYear === highlightedTime.getFullYear()
        ) {
            return "selected-year";
        } else {
            return "year";
        }
    }

    const boundedSetYear = (potentialValue) => {
        const firstUseYear = firstUse.getFullYear();
        const currentYear = new Date().getFullYear();
        if (potentialValue >= firstUseYear && potentialValue <= currentYear) {
            setShownYear(potentialValue);
        }
    }

    const prevYearClicked = () => {  
        boundedSetYear(shownYear - 1)
    }

    const nextYearClicked = () => {
        boundedSetYear(shownYear + 1);
    }

    const yearClicked = () => {
        if (type != "week") {
            setHighlightedTimeUnit(TimeUnit.YEAR);
            setHighlightedTime(new Date(shownYear, 0, 1));
        }
    }

    const getSwitchClass = (timeUnit) => {
        if(type == "week" && timeUnit == TimeUnit.WEEK) {
            return "selected-switch-button";
        }

        if (highlightedTimeUnit === timeUnit
            && shownYear === highlightedTime.getFullYear()
        ) {
            return "selected-switch-button";
        }

        return "switch-button";
    }

    const monthSwitchClicked = () => {
        setHighlightedTimeUnit(TimeUnit.MONTH);
        if (highlightedTime.getFullYear() !== shownYear) {
            setHighlightedTime(new Date(shownYear, 0, 1));
        }
    }

    const weekSwitchClicked = () => {
        if (type == "week") {
            return;
        }
        setHighlightedTimeUnit(TimeUnit.WEEK);
        if (highlightedTime.getFullYear() !== shownYear) {
            setHighlightedTime(new Date(shownYear, 0, 1));
        }
    }

    const monthButtonClicked = (month) => {
        var newDate = new Date(shownYear, months.indexOf(month), 1);
        setHighlightedTime(newDate);
        setHighlightedTimeUnit(TimeUnit.MONTH);
    }

    const isMonthActive = (monthNumber) => {
        var active = true;

        if(firstUse 
            && monthNumber < firstUse.getMonth()
            && shownYear <= firstUse.getFullYear()
        ) {
            active = false;
        }

        if (monthNumber > (new Date()).getMonth()
            && shownYear >= (new Date()).getFullYear()
        ) {
            active = false;
        }

        return active
    }


    const getMonthClass = (month) => {
        const monthNumber = months.indexOf(month);
        if(!isMonthActive(monthNumber)) {
            return "inactive-month-button";
        }

        if ((highlightedTimeUnit == TimeUnit.MONTH) 
            && monthNumber === highlightedTime.getMonth()
            && shownYear === highlightedTime.getFullYear()
        ) {
            return "selected-month-button";
        } else {
            return "month-button";
        }
    }

    const getWeekTowerClass = () => {
        if(type == "week") {
            return "week-only-week-tower";
        }

        return "week-tower";
    }

    const weekButtonClicked = (week) => {
        setHighlightedTime(new Date(shownYear, 0, 1 + (parseInt(week) - 1) * 7));
        setHighlightedTimeUnit(TimeUnit.WEEK);
    }

    const getWeekNumber = (date) => {
        const firstDayOfYear = new Date(date.getFullYear(), 0, 1);
        const pastDaysOfYear = (date - firstDayOfYear) / 86400000;
        return Math.ceil((pastDaysOfYear + firstDayOfYear.getDay() + 1) / 7);
    }

    const isWeekActive = (weekNumber) => {
        var active = true;

        if(firstUse 
            && weekNumber < getWeekNumber(firstUse) 
            && shownYear <= firstUse.getFullYear()
        ) {
            active = false;
        }

        if (weekNumber > getWeekNumber(new Date()) 
            && shownYear >= (new Date()).getFullYear()
        ) {
            active = false;
        }

        return active
    }

    const getWeekClass = (week) => {
        if (!isWeekActive(parseInt(week))) {
            return "inactive-week-button";
        }

        if ((highlightedTimeUnit == TimeUnit.WEEK) 
            && parseInt(week) === getWeekNumber(highlightedTime)
            && shownYear === highlightedTime.getFullYear()
        ) {
            return "selected-week-button";
        }

        return "week-button";
    }

    const onSaveClicked = () => {
        const selectedTimeNewValue = highlightedTime
        const selectedTimeUnitNewValue = highlightedTimeUnit

        setSelectedTime(selectedTimeNewValue);
        setSelectedTimeUnit(selectedTimeUnitNewValue);

        setSelectedTimeLabel(calculateSelectedTimeLabel(selectedTimeNewValue, selectedTimeUnitNewValue));

        setExpanded(false);
        if(onDateSelected) {
            const selectedYear = selectedTimeNewValue.getFullYear();
            const selectedMonth = selectedTimeNewValue.getMonth();

            var from_date;
            var to_date;
            switch (selectedTimeUnitNewValue) {
                case TimeUnit.YEAR:
                    from_date = new Date(selectedYear, 0, 1);
                    to_date = new Date(selectedYear + 1, 0, 1);
                    break;
                case TimeUnit.MONTH:
                    from_date = new Date(selectedYear, selectedMonth, 1);
                    to_date = new Date(selectedYear, selectedMonth + 1, 1);
                    break;
                case TimeUnit.WEEK:
                    const weekNumber = getWeekNumber(selectedTimeNewValue);
                    from_date = new Date(selectedYear, 0, 1 + (weekNumber - 1) * 7);
                    to_date = new Date(selectedYear, 0, 1 + (weekNumber) * 7);
                    break;
                default:
                    break;
            }

            if(from_date && to_date) {

                // Set to date to current date if it's in the future
                if (to_date > new Date()) {
                    to_date = new Date();
                }

                const from_unix = from_date.getTime();
                const to_unix = to_date.getTime();

                console.log("activityOverTime- about to call onDateSelected, ", selectedTimeUnitNewValue)
                onDateSelected(from_unix, to_unix, selectedTimeUnitNewValue);
            }
        }
    }

    const ytdActionClicked = () => {
        const currentYear = (new Date()).getFullYear();
        setHighlightedTimeUnit(TimeUnit.YEAR);
        setHighlightedTime(new Date(currentYear, 0, 1));
        setShownYear(currentYear);
    }

    const cancelClicked = () => {
        closePicker();
    }

    return (
        <div className="DatePicker">
            <div className={getWrapperClass()}>
                <div 
                    className="toggle"
                    onClick={toggleClicked}
                >
                    {selectedTimeLabel}
                    <div className="expand-button">
                        <img src={down_arrow} alt="down arrow" />
                    </div>
                </div>

                {expanded && ( <div className="content-wrapper">
                    <div className="content">
                        <div className="year-row">
                            <div 
                                className="left-arrow"
                                onClick={prevYearClicked}
                            >
                                <img src={left_arrow} alt="left arrow" />
                            </div>
                            <div 
                                className={getYearClass()}
                                onClick={yearClicked}
                            >
                                <div className="year-text">
                                    {shownYear}
                                </div>
                            </div>
                            <div 
                                className="right-arrow"
                                onClick={nextYearClicked}
                            >
                                <img src={right_arrow} alt="right arrow" />
                            </div>
                        </div>

                        <div className="colomns">
                            {type != "week" &&
                                <div className="month-tower">
                                    <div 
                                        className={getSwitchClass(TimeUnit.MONTH)}
                                        onClick={monthSwitchClicked}
                                    >
                                        Måned
                                    </div>
                                    <div className="month-colomn">
                                        { months.map((month, i) => (
                                            <div
                                                key={i}
                                                className={getMonthClass(month)}
                                                onClick={() => monthButtonClicked(month)}
                                            >
                                                {month}
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            }
                            <div className={getWeekTowerClass()}>
                                <div 
                                    className={getSwitchClass(TimeUnit.WEEK)}
                                    onClick={weekSwitchClicked}
                                >
                                    Uge
                                </div>
                                <div className="week-colomn">
                                    {
                                        weekMatrix().map((row, row_index) => (
                                            <div 
                                                key={row_index}
                                                className="week-row"
                                            >
                                                {row.map((week, col_index) => (
                                                    <div 
                                                        key={col_index}
                                                        className={week === ""
                                                            ? "filler"
                                                            : getWeekClass(week)
                                                        }
                                                        onClick={() => {
                                                            if (week !== "") {
                                                                weekButtonClicked(week)
                                                            }
                                                        }}
                                                    >
                                                        {week}
                                                    </div>
                                                ))}
                                            </div>
                                        ))
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="actions-row">
                            <div className="left-section">
                                {type == "week" ?
                                    <div 
                                        className="cancel-button"
                                        onClick={cancelClicked}
                                    >
                                        Annuller
                                    </div>
                                :
                                <div 
                                    className="ytd-button"
                                    onClick={ytdActionClicked}
                                >
                                    {t("YTD")}
                                </div>
                                }
                            </div>
                            <div className="right-section">
                                { type != "week" &&
                                    <div 
                                        className="cancel-button"
                                        onClick={cancelClicked}
                                    >
                                        Annuller
                                    </div>
                                }
                                <div 
                                    className="save-button"
                                    onClick={onSaveClicked}
                                >
                                    Gem
                                </div>
                            </div>
                        </div>
                    </div>
                </div>)}
            </div>
        </div>
    );
}

export default DatePicker;
