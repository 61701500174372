import { useRef, useState, useEffect } from 'react';
import './ActivationsOverTimeChart.css';
import { use } from 'i18next';

const ActivationsOverTimeChart = ({ activityOverTimeData, startDate,  colors, chartHeight = 180, barMargin = 2 }) => {
    const [maxValue, setMaxValue] = useState(0);
    const [mondays, setMondays] = useState([]);
    const [yAxisValues, setYAxisValues] = useState([]);
    const [xAxisValues, setXAxisValues] = useState([]);
    const [xAxisTicks, setXAxisTicks] = useState([]);
    const [dataWithUndefinedInMiddle, setDataWithUndefinedInMiddle] = useState([]);
    const [colorsWithUndefinedInMiddle, setColorsWithUndefinedInMiddle] = useState([]);
    const barChartRef = useRef(null);

    const roundToMultipleAboveOfPowerOfTenBelow = (number) => {
        const numberString = Math.abs(number).toString()
        const indexOfDecimalPoint = numberString.indexOf(".")
        const digitCount = indexOfDecimalPoint >= 0 ? indexOfDecimalPoint : numberString.length
        const firstDigit = parseInt(numberString[0])
        const roundedUpFirstDigit = firstDigit + 1
        const powerOfTen = Math.pow(10, digitCount - 1)

        const roundNumber = (Math.sign(number) * roundedUpFirstDigit * powerOfTen);
        return roundNumber
    }

    const differenceFromRoundNumber = (number) => {
        const roundNumber = roundToMultipleAboveOfPowerOfTenBelow(number)
        return Math.abs(number - roundNumber)
    }

    const findOptimalYTicks = (maxValue, possibleTickCounts) => {
        const roundMax = roundToMultipleAboveOfPowerOfTenBelow(maxValue);

        var bestTicks
        var bestDifference

        for (var i = 1; i <= possibleTickCounts.length; i++) {
            const tickCount = possibleTickCounts[i]
            const tickInterval = roundMax / tickCount
            const diff = differenceFromRoundNumber(tickInterval)
            if (!bestDifference || diff < bestDifference) {
                bestDifference = diff
                bestTicks = tickCount
            }
        }

        return Array.from({length: bestTicks}, (_, i) => Math.round(roundMax / (bestTicks - 1) * i)).reverse();
    }

    const updateXAxisTicks = (_xAxisValues, startDate) => {
        const widthThresholds = [1200]

        // Calculate mondays
        var mondays = [];
        for (var i = 0; i < _xAxisValues.length; i++) {
            var date = new Date(startDate);
            date.setDate(date.getDate() + i);
            if (date.getDay() == 1) {
                mondays.push(i + 1);
            }
        }
        
        setMondays(mondays);

        if (barChartRef.current && barChartRef.current.offsetWidth > widthThresholds[0]) {
            setXAxisTicks(_xAxisValues);
        } else {
            setXAxisTicks(mondays);
        }
    }

    const updateXAxisTicksFromCurrentValues = () => {
        if (xAxisValues && xAxisValues.length > 0 && startDate) {
            updateXAxisTicks(xAxisValues, startDate);
        }
    }
    
    useEffect(() => {

        updateXAxisTicksFromCurrentValues();

        window.addEventListener('resize', updateXAxisTicksFromCurrentValues);

        return () => {
            window.removeEventListener('resize', updateXAxisTicksFromCurrentValues);
        }
    }, []);

    useEffect(() => {
        if (dataWithUndefinedInMiddle && dataWithUndefinedInMiddle.length > 0) {
            // Set the max value for the y-axis
            var max = 0;
            
            for (var dayIndex = 0; dayIndex < dataWithUndefinedInMiddle[0].counts.length; dayIndex++) {
                var sum = 0;

                for (var categoryIndex = 0; categoryIndex < dataWithUndefinedInMiddle.length; categoryIndex++) {
                    sum += dataWithUndefinedInMiddle[categoryIndex].counts[dayIndex];
                }

                if (sum > max) {
                    max = sum;
                }
            }


            // Set the y-axis values
            const yTicks = findOptimalYTicks(max, [2, 3, 4, 5, 6]);
            const roundedMax = yTicks[0];
            setMaxValue(roundedMax);
            setYAxisValues(yTicks);

            console.log("xAxisTicks--- updateXAxisTicks called from useEffect", xAxisTicks)
        }

    }, [dataWithUndefinedInMiddle]);

    useEffect(() => {
        // Set the x-axis values
        if (dataWithUndefinedInMiddle && dataWithUndefinedInMiddle.length > 0) {
            const xAxisValuesNewValue = Array.from({length: dataWithUndefinedInMiddle[0].counts.length}, (_, i) => i + 1)
            setXAxisValues(xAxisValuesNewValue);
            console.log("debug- xAxisValuesNewValue:", xAxisValuesNewValue)
            updateXAxisTicks(xAxisValuesNewValue, startDate);
        }

    }, [dataWithUndefinedInMiddle, startDate]);

    useEffect(() => {
        // Set the data with undefined in the middle
        if (activityOverTimeData && activityOverTimeData.length > 0) {
            const indexOfUndefined = activityOverTimeData.findIndex((element) => element.category === "UNDEFINED");
            const middleIndex = Math.floor((activityOverTimeData.length - 1) / 2);

            var rearrangedList = [];
            var rearrangedColorList = [];

            if (indexOfUndefined == middleIndex) {
                rearrangedList = activityOverTimeData;
                rearrangedColorList = colors;
            } else {
                for (var i = 0; i < activityOverTimeData.length; i++) {
                    var sourceIndex;

                    // Swap the middle and the undefined values.
                    if (i == middleIndex) {
                        sourceIndex = indexOfUndefined;
                    } else if (i == indexOfUndefined) {
                        sourceIndex = middleIndex;
                    }
                    // Continue around the swapped values.
                    else if (i > indexOfUndefined && i < middleIndex) {
                        sourceIndex = i + 1;
                    } else {
                        sourceIndex = i;
                    }

                    rearrangedList.push(activityOverTimeData[sourceIndex]);
                    rearrangedColorList.push(colors[sourceIndex]);
                }
            }

            setDataWithUndefinedInMiddle(rearrangedList);
            setColorsWithUndefinedInMiddle(rearrangedColorList);
        }
    }, [activityOverTimeData]);

    const getXLabelClass = (value) => {
        return (xAxisTicks.indexOf(value) >= 0) ? "x-label" : "x-label-hidden"
    }

    const getSegmentRelativeHeight = (dayIndex, categoryIndex) => {
        return dataWithUndefinedInMiddle[categoryIndex].counts[dayIndex] / maxValue
    }

    const getTableClassName = () => {
        return "barchart charts-css column stacked hide-data hide-primary-axis show-four-secondary-axes data-spacing-" + barMargin.toString()
    }

    return (
        <div className="ActivationsOverTimeChart">
            <div className="barchart-grid">
                <div className="y-axis-container">
                    <div className="y-axis">
                        { yAxisValues.map((value, index) => (
                            <div key={index} className="y-label-container">
                                <div className="y-label-anchor">
                                    <div className="y-label">
                                        {value}
                                        <div className="y-label-width-filler">{maxValue}</div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className="y-axis-width-filler">{maxValue}</div>
                </div>
                <div 
                    ref={barChartRef}
                    className="barchart-cell"
                    style={{"height": chartHeight}}
                >
                    <div className="y-lines">
                        {
                            yAxisValues.map((_, index) => (
                                <div key={index} className="y-line" style={{"bottom": chartHeight/(yAxisValues.length - 1) * index}}></div>
                            ))
                        }
                    </div>

                    <div className="x-lines">
                        {
                            xAxisValues.map((value, index) => (
                                <div 
                                    key={index} 
                                    className="x-line" 
                                    style={{
                                        "left": 20/(xAxisValues.length - 1) * index,
                                        "visibility":  mondays.indexOf(value) >= 0 ? "visible" : "hidden"
                                    }}
                                >
                                </div>
                            ))
                        }
                        <div className="x-lines-right-filler"></div>
                    </div>

                    <table className={getTableClassName()}>
                        <tbody style={{"height": "100%"}}>
                            { dataWithUndefinedInMiddle && 
                                dataWithUndefinedInMiddle.length > 0 &&
                                dataWithUndefinedInMiddle[0].counts.map((day, dayIndex) => (

                                <tr key={dayIndex}>
                                    { dataWithUndefinedInMiddle.map((category, categoryIndex) => (
                                        <td 
                                            key={categoryIndex}
                                            className={categoryIndex == dataWithUndefinedInMiddle.length - 1 ? "bar-top-data" : ""} 
                                            style={{
                                                "--size": "calc(" + getSegmentRelativeHeight(dayIndex, categoryIndex) + ")",
                                                "--color": colorsWithUndefinedInMiddle[categoryIndex],
                                            }}
                                        >
                                        </td>
                                    ))}
                                </tr>
                                
                            ))}
                        </tbody>
                    </table>
                </div>
                <div className="filler"></div>
                <div className="x-axis">
                    { xAxisValues.map((value, index) => (
                        <div 
                            key={index}
                            className="x-label-container"
                            style={{
                                "marginLeft": barMargin,
                                "marginRight": barMargin
                            }}
                        >
                            <div className="x-label-anchor">
                                <div className={ getXLabelClass(value) }>{value}</div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default ActivationsOverTimeChart;