import React, { useEffect, useState, useCallback } from 'react';
import './HomePage.css';
import { useNavigate } from 'react-router-dom';
import { useUser } from './../../contexts/UserContext';
import { useAuthState } from 'react-firebase-hooks/auth';
import { getAvailableLocations, getUnitsLastLog } from './../../hooks/unitDataHooks.js';
import { auth } from './../../firebase';

const HomePage = () => {
    const [locations, setLocations] = useState([]);
    const { userData } = useUser();
    const [user, loadingUser] = useAuthState(auth);
    const navigate = useNavigate();

    const navigateToLocation = useCallback((locationId) => {
        console.log("navigateToLocation")
        navigate(`locations/${locationId}`)
    }, [navigate])

    useEffect(() => {
        const fetchData = async () => {
            try {
                if (userData && userData.companies) {
                    const locations = await getAvailableLocations(userData.companies);
                    setLocations(locations);
                    updateLocationLiveColors(locations);
                }
            } catch (error) {
                console.error("Error fetching locations: ", error);
            }
        };

        fetchData();
    }, [userData]);

    const updateLocationLiveColors = async (locations) => {
        const unitIds = locations.reduce((accumulator, location) => {
            if (!location.units?.length) return accumulator;
            return accumulator.concat(location.units);
        }, []);

        const logs = await getUnitsLastLog(unitIds);
        const updatedLocations = locations.map(location => {
            if (!location.units?.length) return location;

            const worstState = location.units.reduce((accumulator, unitId) => {
                const log = logs.find(_log => _log.unit_id === unitId);
                if (!log) return accumulator;
                if (accumulator === 'Error') return accumulator;
                if (log.state === 'Error') return 'Error';
                if (log.state === 'Ready') return 'Ready';
                return accumulator;
            }, '');

            switch (worstState) {
                case 'Ready':
                    location.live_color = 'var(--success-500)';
                    break;
                case 'Error':
                    location.live_color = 'var(--error-500)';
                    break;
                default:
                    location.live_color = 'var(--gray-500)';
                    break;
            }
            return location;
        });

        setLocations(updatedLocations);
    };

    return (
        <div className="HomePage">
            <div className="wrapper">
                {locations.map((location, index) => (
                    <div key={index} className="card-wrapper" style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                        <div 
                            className="login-container card-container" 
                            style={{ width: '100%', maxWidth: '620px', cursor: 'pointer' }}
                            onClick={() => {
                            		navigateToLocation(location.id)
                            	}
                            }
                        >
                            <div className="card-header">
                                <div className="card-header-title">
                                    {location.name}
                                </div>
                            </div>
                            <div className="card-body" style={{ marginBottom: 0, borderBottomLeftRadius: 0, borderBottomRightRadius: 0 }}>
                                <figure className="img-wrapper">
                                    <img 
                                        className="poster-image"
                                        style={location.units?.length > 0 ? {} : { filter: 'grayscale(100%)' }}
                                        src={location.poster_url}
                                        width="588"
                                        height="250"
                                        alt="Poster image"
                                    />
                                </figure>
                            </div>
                            <div className="card-body location-bar" style={{ borderTopLeftRadius: 0, borderTopRightRadius: 0 }}>
                                <div className="location-bar-left">
                                    <span className="units-count-text">
                                        <div className="units-count-label">Enheder:</div>
                                        <div className="units-count-number">{location.units?.length}</div>
                                    </span>
                                </div>
                                <div className="location-bar-right">
                                    <div className="live-activity-wrapper">
                                        <div className="live-activity-dot" style={{ backgroundColor: location.live_color }}></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default HomePage;
