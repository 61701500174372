import React, { useState } from 'react';

import email_sent_icon from './../../assets/icons/email_sent.svg';
import logo from "./../../assets/icons/Variant=ARIS Robotics, Colour=Green.svg"
import './../../App.css';
import "./LoginPage.css"
import { BrowserRouter } from "react-dom"
import { initializeApp } from 'firebase/app';
import { getAuth, signInWithEmailAndPassword, signOut } from 'firebase/auth';
import { app, auth, db } from './../../firebase';
import { collection, getDocs, query, where, orderBy, limit } from 'firebase/firestore';
import { useAuthState } from 'react-firebase-hooks/auth';
import { useNavigate } from 'react-router-dom';
import { Modal } from '@mui/material';
import ForgotPasswordDialog from './ForgotPasswordDialog/ForgotPasswordDialog';
import { Tooltip } from 'react-tooltip';
import { useTranslation } from 'react-i18next';

const LoginPage = ({ children }) => {
    const { t, i18n } = useTranslation();
    const [email, setEmail] = useState('');
    const [showForgotPasswordDialog, setShowForgotPasswordDialog] = useState(false);
    const [showEmailSentIcon, setShowEmailSentIcon] = useState(false);
    const [password, setPassword] = useState('');
    const [loginError, setLoginError] = useState('');
    const navigate = useNavigate();

    function attemptLogin(email, password) {
    signInWithEmailAndPassword(auth, email, password)
        .then((userCredentials) => {
            const user = userCredentials.user;
            console.log(user)
            const fetchData = async () => {

                try {
                    const collectionRef = collection(db, "units");

                    const q = query(
                      collectionRef,
                      where("user_id", "==", user.uid),  
                    );

                    const querySnapshot = await getDocs(q);
                    const items = querySnapshot.docs.map((doc) => ({
                      id: doc.id,
                      ...doc.data()
                    }));

                    const userDataResult = items[0]
                    console.log("userDataResult", userDataResult)
                    navigate("/", {state: {"userData": userDataResult}})
                } catch (error) {
                    console.error("userDataResult Error fetching data: ", error);
                } finally {
                    //setLoading(false);
                }
            }
        })
        .catch((error) => {
            const errorCode = error.code;
            setLoginError(error.message);
        })
}

const logout = () => {
  signOut(auth);
};

function loginPressed() {
    attemptLogin(email, password)
}

const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
        attemptLogin(email, password)
    }
};

const forgotPasswordClicked = () => {
    setShowForgotPasswordDialog(true)
}

const closeDialog = () => {
    setShowForgotPasswordDialog(false)
}

    return (
        <div 
            className="LoginPage"
            onKeyDown={handleKeyPress}
        >
            <img 
                className="main-logo"
                src={logo} 
                alt="Aris Robotics"
            />
            <div className="login-container card-container">
                <div className="card-header">
                    <div className="card-header-title" >
                        Velkommen
                    </div>
                    <div className="card-header-actions">
                        {   showEmailSentIcon &&
                            <div 
                                className="email-sent-icon-wrapper"
                            >
                                <img 
                                    className="icon"
                                    src={email_sent_icon}
                                    alt="Email sent"
                                    data-tooltip-id="tooltip1"
                                    data-tooltip-content={t("Gendannelses-email sendt")}
                                    data-tooltip-place="top"
                                />
                                <Tooltip 
                                    id="email-sent-tooltip"
                                    placement="top"
                                    effect="solid"
                                    className="aris-tooltip"
                                />
                            </div>
                        }
                    </div>
                </div>
                <div className="card-body">
                    <div className="login_card">
                        <input
                            className="aris-input"
                            type="email"
                            value={email}
                            onChange={(e)=>{setEmail(e.target.value)}}
                            placeholder="Email"
                        />
                        <input 
                            className="aris-input"
                            type="password"
                            value={password}
                            onChange={(e)=>{setPassword(e.target.value)}}
                            placeholder="Adgangskode"
                        />
                        <div 
                            className="forgot-password"
                            onClick={() => forgotPasswordClicked()}
                        >
                            Glemt kodeord?
                        </div>
                    </div>
                    <div className="card-actions">
                        <div className="login-error">
                            {loginError}
                        </div>
                        <button 
                            className="aris-button-primary"
                            type="submit"
                            onClick={loginPressed}
                        >
                            Login
                        </button>
                    </div>
                </div>
            </div>
            <Modal
                open={showForgotPasswordDialog}
                onClose={closeDialog}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <div>
                    {
                        showForgotPasswordDialog 
                        ? <ForgotPasswordDialog onClose={closeDialog} />
                        : <></> 
                    }
                </div>
            </Modal>
        </div>
    );
}

export default LoginPage;
