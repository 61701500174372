import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from "react-router-dom";
import { UserProvider } from './contexts/UserContext.js';
import { SecondHeaderProvider } from './contexts/SecondHeaderContext.js';
import { UnitDataCacheProvider } from './contexts/UnitDataCacheContext';
import './i18n';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <UserProvider>
        <SecondHeaderProvider>
            <UnitDataCacheProvider>
                <App />
            </ UnitDataCacheProvider>
        </SecondHeaderProvider>
    </UserProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
