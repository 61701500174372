import ReactECharts from 'echarts-for-react'; // ECharts wrapper for React
import React, { useEffect, useState, useCallback, act } from 'react';
import { Tooltip } from 'react-tooltip';
import { useSecondHeader } from './../../contexts/SecondHeaderContext';
import { useUnitDataCache } from './../../contexts/UnitDataCacheContext';
import './WadeReportPage.css'; // Import the corresponding CSS
import questionCircle from "./../../assets/icons/question-circle.svg"
import { useParams, useLocation } from 'react-router-dom';
import { useTranslation, Trans } from 'react-i18next';
import DatePicker from "./../../components/DatePicker/DatePicker.jsx"
import increase_icon from "./../../assets/icons/increase_icon.svg"
import decrease_icon from "./../../assets/icons/decrease_icon.svg"
import DonutChart from '../../components/DonutChart/DonutChart.jsx';
import ActivationsOverTimeChart from '../../components/ActivationsOverTimeChart/ActivationsOverTimeChart.jsx';

const WadeReportPage = () => {
    const location = useLocation();

    const getMondayOfCurrentWeek = () => {
        const today = new Date();
        const dayOfWeek = today.getDay(); // 0 (Sun) to 6 (Sat)
        const diff = (dayOfWeek === 0 ? -6 : 1) - dayOfWeek; // Calculate the difference to Monday
        const monday = new Date(today.setDate(today.getDate() + diff));
        monday.setHours(0, 0, 0, 0); // Set time to the start of the day
        return monday;
    }

    const { t, i18n } = useTranslation();
    const { locationId, unitId } = useParams();

    const [firstUse, setFirstUse] = useState(new Date(new Date().getFullYear(), 0, 1)); // Start of the current year
    const [colors, setColors] = useState([
        '#74D5F2',
        '#BD92F4',
        '#81F3D5',
        '#FF8989',
        '#81ECF3',
        '#53C0FF',
        '#ED9BD2',
        '#FF89B0',
        '#90AAFF',
        '#81F3AD'
    ]);

    const year_start_unix = new Date(new Date().getFullYear(), 0, 1).getTime();
    const now_unix = new Date().getTime();
    const activationsFromDefault = year_start_unix;
    const activationsToDefault = now_unix;
    const activationsPrevFromDefault = new Date(new Date().getFullYear() - 1, 0, 1).getTime();
    const activationsPrevToDefault = new Date(new Date().getFullYear() - 1, 11, 31).getTime();
    const [activationsFrom, setActivationsFrom] = useState(activationsFromDefault);
    const [activationsTo, setActivationsTo] = useState(activationsToDefault);
    const [activationsPrevFrom, setActivationsPrevFrom] = useState(activationsPrevFromDefault);
    const [activationsPrevTo, setActivationsPrevTo] = useState(activationsPrevToDefault);
    const [activationsIsTd, setActivationsIsTd] = useState(true);

    // Default heatmap to show the current week
    const activityHeatmapFromDefault = getMondayOfCurrentWeek().getTime();
    const activityHeatmapToDefault = now_unix;
    const [activityHeatmapFrom, setActivityHeatmapFrom] = useState(activityHeatmapFromDefault);
    const [activityHeatmapTo, setActivityHeatmapTo] = useState(activityHeatmapToDefault);
    const [activityHeatmapUnit, setActivityHeatmapUnit] = useState("uge");

    const activityOverTimeFromDefault = year_start_unix;
    const activityOverTimeToDefault = now_unix;
    const activityOverTimeUnitDefault = "year";
    const [activityOverTimeFrom, setActivityOverTimeFrom] = useState(activityOverTimeFromDefault);
    const [activityOverTimeTo, setActivityOverTimeTo] = useState(activityOverTimeToDefault);
    const [activityOverTimeUnit, setActivityOverTimeUnit] = useState(activityOverTimeUnitDefault);

    const [barChartOption, setBarChartOption] = useState({});
    const [barChartOption2, setBarChartOption2] = useState({});
    const [timeUnit, setTimeUnit] = useState('år'); // Default to 'år' (year)

    const [activationsUnit, setActivationsUnit] = useState('year');

    const [isWadeReportMode, setIsWadeReportMode] = useState(false);
    const [isWadeReportTimeMonth, setIsWadeReportTimeMonth] = useState(null);

    const [dountValues, setDonutValues] = useState([]);

    const {
        activationsCounts,
        activationsTotal,
        activationsRelativePercentage,
        activityOverTimeData,
        activityHeatmapData,
        getActivationsData,
        getActivityOverTimeData,
        getActivityHeatmapData
    } = useUnitDataCache(); 

    const { currentUnit, getFirstUse, getUnitData } = useSecondHeader();

    const daysOfWeek = ['Man', 'Tir', 'Ons', 'Tor', 'Fre', 'Sat', 'Sun'];

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const isWadeReportMode = true;
        const wadeReportTimeIsMonth = true;
        setIsWadeReportMode(isWadeReportMode)
        setIsWadeReportTimeMonth(wadeReportTimeIsMonth)

        const startOfLastCalendarMonth = new Date(new Date().getFullYear(), new Date().getMonth() - 1, 1).getTime();
        const endOfLastCalendarMonth = new Date(new Date().getFullYear(), new Date().getMonth(), 0).getTime();

        if (wadeReportTimeIsMonth) {
            setTimeUnit('måned');
            setActivationsUnit('month');
            setActivityOverTimeUnit('month');
            setActivationsFrom(startOfLastCalendarMonth);
            setActivationsTo(endOfLastCalendarMonth);
            
            setActivityOverTimeFrom(startOfLastCalendarMonth);
            setActivityOverTimeTo(endOfLastCalendarMonth);

            activationsDateSelected(startOfLastCalendarMonth, endOfLastCalendarMonth, 'month');
            activationsDateSelected(startOfLastCalendarMonth, endOfLastCalendarMonth, 'month');
        }
        
    }, [location])

    useEffect(() => {
        setDonutValues(activationsCounts.map((category, index) => {return category.count}))

        const indexOfUndefined = activationsCounts.findIndex((category) => category.category.includes('UNDEFINED'));
        if (indexOfUndefined !== -1) {
            colors.splice(indexOfUndefined, 0, '#A5A5A5');
        }
    }, [activationsCounts])

    useEffect(() => {   
        if (activityOverTimeData && activityOverTimeFrom && activityOverTimeTo && activityOverTimeUnit) {

            var timeDependentOptions = {}

            switch (activityOverTimeUnit) {
                case "week":
                    timeDependentOptions = {
                        xAxis: {
                            data: ['Man', 'Tir', 'Ons', 'Tor', 'Fre', t('Sat'), t('Sun')],
                            axisTick: {
                                alignWithLabel: false
                            },
                            splitLine: {
                                show: false,
                            }
                        }
                    };
                    break;
                case "month":
                    // stops = this.calculateMonthNumbers(this.data_cache.activity_over_time_context()!.start_unix)
                    
                    const date = new Date(activityOverTimeFrom);
                    const daysInMonth = new Date(date.getFullYear(), date.getMonth(), 0).getDate();

                    timeDependentOptions = {
                        xAxis: {
                            data: Array.from(
                                { length: daysInMonth }, 
                                (value, index) => (index + 1).toString()
                            ),
                            axisTick: {
                                alignWithLabel: true
                            },
                            splitLine: {
                                show: true,
                                interval: (index) => {
                                    const date = new Date(activityOverTimeFrom);
                                    const dayOfMonth = index + 1;
                                    const testDate = new Date(date.getFullYear(), date.getMonth(), dayOfMonth);
                                    if (index === 0 || index === new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate() - 1) {
                                        return false;
                                    }
                                    return testDate.getDay() === 0; // Sunday
                                },
                                lineStyle: {
                                    color: '#575757',
                                    type: 'solid',
                                    cap: 'round',
                                }
                            }
                        },
                    }
                    break;
                case "year":
                    timeDependentOptions = {
                        xAxis: {
                            data: ['Jan', 'Feb', 'Mar', 'Apr', 'Maj', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dec'],
                            axisTick: {
                                alignWithLabel: false
                            },
                            splitLine: {
                                show: false,
                            }
                        }
                    }
                    break;
            }

            let barChartData = activityOverTimeData.map((category, i) => {
                let obj = {
                    data: category.counts.map(val => {
                        return {
                            value: val
                        }
                    }),
                    name: t(category.category),
                    type: 'bar',
                    stack: 'a',
                    barMaxWidth: '50%',
                    itemStyle: {}
                }
                if (category.category.includes('UNDEFINED')) {
                    obj.itemStyle = {
                        color: '#A5A5A5',
                    }
                } else {
                    obj.itemStyle = {
                        color: colors[i],
                    }
                }
    
                return obj;
            }).reverse()
    
            // Round the corners of the top bar in each stack
            addBarBorderRadius(barChartData)

            var object = {
                ...timeDependentOptions,
                tooltip: {
                    trigger: 'item',
                    axisPointer: {
                        type: 'shadow'
                    }
                },
                color: colors,
                yAxis: {
                    type: 'value',
                    splitLine: {
                        lineStyle: {
                            type: [8, 3],
                            color: '#575757'
                        }
                    },
                    animation: false,
                    axisLabel: {
                        fontSize: 14,
                        color: 'white'
                    }
                },
                grid: {
                    left: 45,
                    top: 10,
                    right: 5,
                    bottom: 20
                },
                textStyle: {
                    fontFamily: 'Montserrat',
                },
                series: barChartData
            }

            setBarChartOption2(object);
        }
    }, [activityOverTimeData]);

    useEffect(() => {
        console.log("activityOverTime- data changed", activityOverTimeData)
        if (activityOverTimeData) {
            var timeDependentOptions = {}

            switch (activityOverTimeUnit) {
                case "week":
                    timeDependentOptions = {
                        xAxis: {
                            data: ['Man', 'Tir', 'Ons', 'Tor', 'Fre', 'L\u00f8', 'S\u00f8n'],
                            axisTick: {
                                alignWithLabel: false
                            },
                            splitLine: {
                                show: false,
                            }
                        }
                    };
                case "month":
                    // stops = this.calculateMonthNumbers(this.data_cache.activity_over_time_context()!.start_unix)
                    timeDependentOptions = {
                        xAxis: {
                            data: Array.from(
                                { length: new Date(activityOverTimeFrom).getDate() }, 
                                (value, index) => (index + 1).toString()
                            ),
                            axisTick: {
                                alignWithLabel: true
                            },
                            splitLine: {
                                show: true,
                                interval: (index) => {
                                    const date = new Date(activityOverTimeFrom);
                                    const dayOfMonth = index + 1;
                                    const testDate = new Date(date.getFullYear(), date.getMonth(), dayOfMonth);
                                    if (index === 0 || index === new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate() - 1) {
                                        return false;
                                    }
                                    return testDate.getDay() === 0; // Sunday
                                },
                                lineStyle: {
                                    color: '#575757',
                                    type: 'solid',
                                    cap: 'round',
                                }
                            }
                        },
                    }
                case "year":
                    timeDependentOptions = {
                        xAxis: {
                            data: ['Jan', 'Feb', 'Mar', 'Apr', 'Maj', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dec'],
                            axisTick: {
                                alignWithLabel: false
                            },
                            splitLine: {
                                show: false,
                            }
                        }
                    }
            }
            
            let barChartData = activityOverTimeData.map((category, i) => {
                let obj = {
                    data: category.counts.map(val => {
                        return {
                            value: val
                        }
                    }),
                    name: t(category.category),
                    type: 'bar',
                    stack: 'a',
                    barMaxWidth: '50%',
                    itemStyle: {}
                }
                if (category.category.includes('UNDEFINED')) {
                    obj.itemStyle = {
                        color: '#A5A5A5',
                    }
                } else {
                    obj.itemStyle = {
                        color: colors[i],
                    }
                }
    
                return obj;
            }).reverse()
    
            // Round the corners of the top bar in each stack
            addBarBorderRadius(barChartData)

            setBarChartOption({
                ...timeDependentOptions,
                tooltip: {
                    trigger: 'item',
    
                },
                color: colors,
                yAxis: {
                    type: "value",
                    splitLine: {
                        lineStyle: {
                            type: [8, 3],
                            color: '#575757'
                        }
                    },
                    animation: false,
                    axisLabel: {
                        fontSize: 14,
                        color: 'white'
                    }
                },
                grid: {
                    left: 45,
                    top: 10,
                    right: 5,
                    bottom: 20
                },
                textStyle: {
                    fontFamily: 'Montserrat',
                },
                series: barChartData
            });
        }
    }, [activityOverTimeData]);

    const fetchHeatmapData = async () => {
        console.log("heatMap- fetchHeatmapData");
        console.log("heatMap- Current unit:", currentUnit);
        console.log("heatMap- From:", activityHeatmapFrom);
        if (!currentUnit) return;

        try {
            await getActivityHeatmapData(unitId, activityHeatmapFrom, activityHeatmapTo);
        } catch (error) {
            console.error('Error fetching activity heatmap data:', error);
        }
    }

    const activityDateSelected = async (start_unix, end_unix) => {
        console.log("activityDateSelected");
        console.log("start_unix", start_unix);
        console.log("end_unix", end_unix);
        setActivityHeatmapFrom(start_unix);
        setActivityHeatmapTo(end_unix);
    }


    const getShade = (baseColor, count, maxCount) => {
        if (count === 0) return 'white';
        const lightenFactor = (1 - count / maxCount) * 1;
        return lightenColor(baseColor, lightenFactor);
    };

    // Function to lighten a color by a given factor
    const lightenColor = (color, factor) => {
        const colorObj = hexToRgb(color);
        const r = Math.round(colorObj.r + (255 - colorObj.r) * factor);
        const g = Math.round(colorObj.g + (255 - colorObj.g) * factor);
        const b = Math.round(colorObj.b + (255 - colorObj.b) * factor);
        return `rgb(${r},${g},${b})`;
    };

    // Convert hex color to RGB
    const hexToRgb = (hex) => {
        const bigint = parseInt(hex.slice(1), 16);
        return {
            r: (bigint >> 16) & 255,
            g: (bigint >> 8) & 255,
            b: bigint & 255,
        };
    };

    const fetchActivityData = async () => {
        console.log("activations- Fetching activity data...");
        console.log("activations- Current unit:", currentUnit);
        if (!currentUnit) return;
        await getActivationsData(unitId, activationsFrom, activationsTo, activationsPrevFrom, activationsPrevTo);
    }

    const fetchActivityOverTimeData = async () => {
        console.log("activityOverTime- Fetching activity over time data...");
        console.log("activityOverTime- Current unit:", currentUnit);
        console.log("activityOverTime- From:", activityOverTimeFrom);
        console.log("activityOverTime- To:", activityOverTimeTo);
        if (!currentUnit || (activityOverTimeFrom === undefined) || (activityOverTimeTo === undefined) || activityOverTimeUnit == undefined) return;
        try {
            await getActivityOverTimeData(unitId, activityOverTimeFrom, activityOverTimeTo, activityOverTimeUnit);
        } catch (error) {
            console.error('activityOverTime-- Error fetching activity over time data:', error);
        }
    }

    // On mount
    useEffect(() => {
        console.log("Fetching data... (on mount)");

        if (!currentUnit) {
            console.log("Fetching unit data...", unitId);
            console.log("Unit id:")
            console.log(unitId)
            getUnitData(unitId)
        }

    }, []);

    useEffect(() => {
        fetchActivityData();

        const intervalId = setInterval(async () => {
            fetchActivityData();
        }, 10000);

        return () => {
            intervalId && clearInterval(intervalId);
        }
    }, [currentUnit, activationsFrom, activationsTo, activationsPrevFrom, activationsPrevTo]);
    
    useEffect(() => {
        fetchHeatmapData();

        const heatMapInterval = setInterval(async () => {
            fetchHeatmapData();
        }, 10000);

        return () => {
            heatMapInterval && clearInterval(heatMapInterval);
        }
    }, [currentUnit, activityHeatmapFrom, activityHeatmapTo]);

    useEffect(() => {
        console.log("activityOverTime- useEffect, time unit", activityOverTimeUnit)
        fetchActivityOverTimeData();

        const intervalId = setInterval(async () => {
            fetchActivityOverTimeData();
        }, 10000);

        return () => {
            intervalId && clearInterval(intervalId);
        }
    }, [currentUnit, activityOverTimeFrom, activityOverTimeTo, activityOverTimeUnit]);

    useEffect(() => {
        if (activityOverTimeData) {
            const barChartData = activityOverTimeData.map((category, i) => {
                const itemStyle = category.category.includes('UNDEFINED')
                    ? { color: '#A5A5A5' }
                    : { color: colors[i] };

                return {
                    data: category.counts.map(val => ({ value: val })),
                    name: category.category,
                    type: 'bar',
                    stack: 'a',
                    barMaxWidth: '50%',
                    itemStyle,
                };
            }).reverse();

            addBarBorderRadius(barChartData);
        }
    }, [currentUnit, activityOverTimeData, colors]);

    useEffect(() => {
        /*
        switch (activityOverTimeUnit) {
            case "week":
                setBarChartOption({...barChartOption,
                    xAxis: {
                        data: ['Man', 'Tir', 'Ons', 'Tor', 'Fre', 'Lør', 'Søn'],
                        axisTick: {
                            alignWithLabel: false
                        },
                        splitLine: {
                            show: false,
                        }
                    }
                });
                break
            case "month":
                // stops = this.calculateMonthNumbers(this.data_cache.activity_over_time_context()!.start_unix)
                setBarChartOption({...barChartOption,
                    xAxis: {
                        data: Array.from(
                            { length: new Date(activityOverTimeFrom).getDate() }, 
                            (value, index) => (index + 1).toString()
                        ),
                        axisTick: {
                            alignWithLabel: true
                        },
                        splitLine: {
                            show: true,
                            interval: (index) => {
                                const date = new Date(activityOverTimeFrom);
                                const dayOfMonth = index + 1;
                                const testDate = new Date(date.getFullYear(), date.getMonth(), dayOfMonth);
                                if (index === 0 || index === new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate() - 1) {
                                    return false;
                                }
                                return testDate.getDay() === 0; // Sunday
                            },
                            lineStyle: {
                                color: '#575757',
                                type: 'solid',
                                cap: 'round',
                            }
                        }
                    },
                });
                break
            case "year":
                setBarChartOption({...barChartOption,
                    xAxis: {
                        data: ['Jan', 'Feb', 'Mar', 'Apr', 'Maj', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dec'],
                        axisTick: {
                            alignWithLabel: false
                        },
                        splitLine: {
                            show: false,
                        }
                    }
                });
                break
        }
                */
    }, [activityOverTimeUnit]);

    const addBarBorderRadius = (barChartData) => {
        for (let dataIndex = 0, moreValue = true; moreValue; dataIndex++) {
            moreValue = false;
            for (let i = 0; i < barChartData.length; i++) {
                const data = barChartData[i].data;
                if (dataIndex < data.length) {
                    moreValue = true;
                    if (data[dataIndex].value !== 0) {
                        data[dataIndex] = { value: data[dataIndex].value };
                        break;
                    }
                }
            }
        }

        for (let dataIndex = 0, moreValue = true; moreValue; dataIndex++) {
            moreValue = false;
            for (let i = barChartData.length - 1; i >= 0; i--) {
                const data = barChartData[i].data;
                if (dataIndex < data.length) {
                    moreValue = true;
                    if (data[dataIndex].value !== 0) {
                        data[dataIndex] = {
                            value: data[dataIndex].value,
                            itemStyle: {
                                borderRadius: [50, 50, 0, 0],
                            },
                        };
                        break;
                    }
                }
            }
        }
    };

    const visualizeActivationsPercentage = (count) => {
        const num = parseFloat(((count / activationsTotal()) * 100).toFixed(1));
        return isFinite(num) ? num : 0;
    };

    const activationsDateSelected = async (start_unix, end_unix, time_unit) => {

        setActivationsFrom(start_unix);
        setActivationsTo(end_unix);

        const previousPeriodStart = new Date(start_unix);
        previousPeriodStart.setFullYear(previousPeriodStart.getFullYear() - (time_unit === "year" ? 1 : 0));
        previousPeriodStart.setMonth(previousPeriodStart.getMonth() - (time_unit === "month" ? 1 : 0));
        previousPeriodStart.setDate(previousPeriodStart.getDate() - (time_unit === "week" ? 7 : 0));

        // Calculate the end of the previous period so it is has the same length as the current period
        const prevEnd = new Date(end_unix);
        prevEnd.setFullYear(prevEnd.getFullYear() - (time_unit === "year" ? 1 : 0));
        prevEnd.setMonth(prevEnd.getMonth() - (time_unit === "month" ? 1 : 0));
        console.log("prev- prev end 1", prevEnd)
        prevEnd.setDate(prevEnd.getDate() - (time_unit === "week" ? 7 : 0));

        setActivationsPrevFrom(previousPeriodStart.getTime());
        console.log("prev- prev end", prevEnd)
        setActivationsPrevTo(prevEnd.getTime())

        setActivationsUnit(time_unit);
    };

    const createDonutOption = () => {
        const donutChartData = activationsCounts.map((category, index) => {
            const color = category.category.includes('UNDEFINED') ? '#A5A5A5' : colors[index];
            return {
                value: category.count,
                name: category.category,
                itemStyle: { color }
            };
        });
    
        return {
            color: colors,
            series: [
                {
                    name: 'Categories',
                    type: 'pie',
                    radius: ['35%', '74%'],
                    avoidLabelOverlap: true,
                    itemStyle: {
                        borderRadius: 6,
                        borderJoin: 'round',
                        borderWidth: 2,
                        borderColor: '#1c1c1c'
                    },
                    emphasis: {
                        disabled: true
                    },
                    cursor: 'default',
                    label: {
                        show: true,
                        position: 'inside',
                        fontFamily: 'Montserrat',
                        fontSize: 12,
                        formatter: (data) => data.value === 0 ? '' : `${((data.value / activationsCounts.reduce((acc, curr) => acc + curr.count, 0)) * 100).toFixed(1)}%`,
                    },
                    labelLine: {
                        show: false
                    },
                    data: donutChartData,
                    center: ['50%', '40%'],
                }
            ]
        };
    };
    
     // Calculate totals for each day across all categories
     const activityHeatmapTotals = activityHeatmapData.length > 0
     ? activityHeatmapData[0].counts.map((_, colIndex) =>
         activityHeatmapData.reduce((total, category) => total + category.counts[colIndex], 0)
     )
     : [];

    // Function to calculate the average count for each day
    const getAvgCountsHeatmap = (counts) => {
        if (activityHeatmapUnit === 'uge') {
            const validCounts = counts.slice(0, 7); // Assuming 'uge' means we only care about the first 7 days
            const sum = validCounts.reduce((acc, count) => acc + count, 0);
            if (validCounts.length == 0) {
                return 0;
            }
            return Math.round(sum / validCounts.length);
        }
        return 0;
    };

    const getCellStyle = (count, isLast, isAverage = false) => {
        if (count === 0) return { backgroundColor: 'white' };
        if (isAverage) return { backgroundColor: isLast ? '#A5A5A5' : 'var(--success-400)' };
        return { backgroundColor: isLast ? '#A5A5A5' : colors[count % colors.length] };
    };

    const getCategoryLabel = useCallback((category) => {
        return t(category);
    }, [t]);

    useEffect(() => {
        if (activityOverTimeData.length > 0) {
            const seriesData = activityOverTimeData.map((category, index) => ({
                name: category.category,
                type: 'bar',
                stack: 'total',
                barMaxWidth: '50%',
                itemStyle: { color: (index == activityOverTimeData.length - 1 ? "#A5A5A5" : colors[index % colors.length]) },
                data: category.counts,
            })).reverse();

                        /*
            setBarChartOption({
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'shadow',
                    },
                },
                xAxis: {
                    type: 'category',
                    data: xAxisData,
                    axisTick: { alignWithLabel: true },
                },
                yAxis: {
                    type: 'value',
                },
                grid: {
                    left: '3%',
                    right: '4%',
                    bottom: '3%',
                    containLabel: true,
                },
                series: seriesData,
            });*/

            let barChartData = activityOverTimeData.map((category, i) => {
                let obj = {
                    data: category.counts.map(val => {
                        return {
                            value: val
                        }
                    }),
                    name: category.category,
                    type: 'bar',
                    stack: 'a',
                    barMaxWidth: '50%',
                    itemStyle: {}
                }
                if (category.category.includes('UNDEFINED')) {
                    obj.itemStyle = {
                        color: '#A5A5A5',
                    }
                } else {
                    obj.itemStyle = {
                        color: colors[i],
                    }
                }

                return obj;
            }).reverse()

            // Round the corners of the top bar in each stack
            addBarBorderRadius(barChartData)
            /*
            setBarChartOption({...barChartOption,
                tooltip: {
                    trigger: 'item',
    
                },
                color: colors,
                yAxis: {
                    type: "value",
                    splitLine: {
                        lineStyle: {
                            type: [8, 3],
                            color: '#575757'
                        }
                    },
                    animation: false,
                    axisLabel: {
                        fontSize: 14,
                        color: 'white'
                    }
                },
                grid: {
                    left: 45,
                    top: 10,
                    right: 5,
                    bottom: 20
                },
                textStyle: {
                    fontFamily: 'Montserrat',
                },
                series: barChartData
            }, { replaceMerge: ['series'] });
            */
        }
    }, [activityOverTimeData, colors, timeUnit]);

    const sumArray = (array) => array.reduce((acc, curr) => acc + curr, 0);

    const visualizeActivityOverTimePercentage = (count) => {
        const totalCounts = sumArray(activityOverTimeData.flatMap((category) => category.counts));
        return totalCounts > 0 ? parseFloat(((count / totalCounts) * 100).toFixed(1)) : 0;
    };

    const barDateSelected = (start_unix, end_unix, time_unit) => {
        setActivityOverTimeFrom(start_unix);
        setActivityOverTimeTo(end_unix);
        console.log("activityOverTime- setting time unit", time_unit)
        setActivityOverTimeUnit(time_unit);
    }

    const getClassForActivationsOverTimeCard = useCallback(() => {
        var className = "card-container"

        if (isWadeReportMode && isWadeReportTimeMonth) {
            className += " wade-report-mode-month"
        } else {
            className += "bar-card"
        }

        return className

    }, [isWadeReportMode, isWadeReportTimeMonth])

    const formatDateForDateLine = (date) => {

        // Create an options object for the custom format
        const options = { day: 'numeric', month: 'long', year: 'numeric' };

        // Format the date using the specified locale and options
        const formattedDate = new Intl.DateTimeFormat('da-DK', options).format(date);

        return formattedDate
    }

    const getDateLineString = (from, to) => {
        return `${formatDateForDateLine(new Date(from))} - ${formatDateForDateLine(new Date(to))}`
    }

    return (
        <div className="WadeReportPage">
        <div className="date-line">{getDateLineString(activityOverTimeFrom, activityOverTimeTo)}</div>
            <div className="wrapper">
                <div className="grid-wrapper">
                    <div 
                        className="card-container activations-card"
                    >
                        <div className="card-header">
                            <div className="card-header-title">
                                Aktiveringer
                            </div>
                        </div>
                        <div style={{display: "flex", flex: 1, flexDirection: "column", height: "100%"}}>
                            <div className="card-body top-bar" style={{fontSize: 32, position: "relative"}}>
                                <p style={{margin: 0}}>
                                    {
                                        activationsTotal()
                                    }
                                </p>
                                { !isWadeReportMode && (
                                    <img 
                                        className="question-icon" 

                                        data-tooltip-id="tooltip1"
                                        data-tooltip-content={t("DASHBOARD.ACTIVATIONS_?")}
                                        data-tooltip-place="top"

                                        style={{top: 8, right: 8}}
                                        src={questionCircle} alt="?" 
                                    />
                                )}
                                <Tooltip 
                                    id="tooltip1" 
                                    place="top" 
                                    effect="solid"
                                    className="aris-tooltip questionMarkTooltip"
                                />
                                <div className="units-count-text ">
                                    <p className="units-count-label" style={{margin: 0}}>
                                        { isFinite(activationsRelativePercentage) &&
                                        <span>
                                        {(activationsRelativePercentage == 0 ?
                                            <span>Ingen ændring </span>
                                        :
                                            <span>
                                                {activationsRelativePercentage > 0 
                                                ? 
                                                <span className="trend_icon">
                                                    <img src={increase_icon}/>
                                                </span>
                                                : 
                                                <span className="trend_icon">
                                                    <img src={decrease_icon}/>
                                                </span>
                                                }
                                                {activationsRelativePercentage > 0 
                                                ? <span className="trend-label-increasing">
                                                    {Math.abs(activationsRelativePercentage)}% mere 
                                                </span>
                                                : <span className="trend-label-decreasing">
                                                    {Math.abs(activationsRelativePercentage)}% mindre 
                                                </span>
                                                }
                                            </span>
                                        )}
                                         {activationsRelativePercentage == 0 ? "" : " end " }
                                        </span>
                                        }

                                        { !isFinite(activationsRelativePercentage) &&
                                            <span>Ingen data</span>
                                        }
                                        {activationsUnit == "week" ? " ugen " : 
                                        activationsUnit == "month" ? " m\u00e5neden " 
                                        : " \u00e5ret "}
                                        før
                                    </p>
                                </div>
                            </div>

                            <div className="card-body bottom-bar" style={{justifyContent: "start"}}>
                                <div className="data-cards-wrapper">

                                    {
                                        activationsCounts.map((category, index) => (
                                            <div 
                                                key={index}
                                                className="data-card-large units-count-text bordered" 
                                                style={{borderLeft: index === (activationsCounts.length - 1) ? '2px solid var(--gray-400)' : '2px solid ' + colors[index % colors.length]}}
                                            >
                                                <div className="units-count-label">{getCategoryLabel(category.category)}</div>
                                                <div className="data-card-count">{category.count}</div>
                                            </div>
                                        ))
                                    }
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="card-container donut-card-wade-report">
                        <div className="card-header">
                            <div className="card-header-title">Kategorifordeling</div>
                        </div>
                        <div className="donut-section-body-wade-report-month">
                            <DonutChart absoluteValues={dountValues} outerRadius={144} innerRadius={76} colors={colors}/>
                        </div>
                    </div>


                </div>
            </div>
                    <div className="barchart-wrapper">
                        <div className="card-container barchart-card">
                            <div className="card-header">
                                <div className="card-header-title">
                                    Aktiveringer over tid
                                </div>
                            </div>

                            <div className="card-body">
                                <ActivationsOverTimeChart 
                                    activityOverTimeData={activityOverTimeData} 
                                    startDate={activityOverTimeFrom}
                                    colors={colors}
                                    chartHeight={220}
                                />
                            </div>
                        </div>
                    </div>
        </div>
    );
};

export default WadeReportPage;
